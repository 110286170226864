jQuery(document).ready(function() {
 
        jQuery('.js-step3').on('change', '.js-na-check', function(){
        if (jQuery(this).prop('checked') && !jQuery(this).siblings().hasClass('disabled')) {
              jQuery('.js-container-price').fadeOut();
        } else {
              jQuery('.js-container-price').fadeIn();
        }
    });

});