var preventSubmit = true;
var validationTimeout;

jQuery('document').ready(function() {

    $('.js-container-speed-entry').on('keyup', '.js-input-part-number', function(ev) {
        var input = $(this);
        delay(input, 1000);
    });

    var delay = (function(){
    var timer = 0;
      return function(input, ms){
        clearTimeout (timer);
        timer = setTimeout(function(){
          validatePartNumber(input)
        }, ms);
      };
    })();

    function validatePartNumber(textfield) 
    {
        var url = jQuery('.js-getlinecode-url').val();
        var line = textfield.closest('.js-line');
        var target = line.find('.js-select-linecode');
        var productCode = line.find('.js-input-part-number').val();

        line.find('.fa-spin').remove(); 
        line.find('.inlined').append('<i class="fa fa-circle-o-notch fa-spin fa-fw color--secondary"></i>');

        if (productCode != '') {
            $.ajax({
                url:url,
                dataType: 'JSON',
                type: 'POST',
                data: {
                    productCode : productCode
                },
                error: function(data){
                    line.find('.fa-spin').remove(); 
                    line.find('.fa-circle.fa-stack-2x').css('background-color', '#eb2031');
                    line.find('.fa-stack .fa-stack-1x').html('').removeClass('fa-check').addClass('fa-times').removeClass('fa-inverse').css('width', '94%');
                    line.find('.js-select-linecode').prop('disabled', true);
                    if(!jQuery('.fa-stack .fa-stack-1x').hasClass('fa-check')){
                         jQuery('.js-submit-button').prop('disabled', true);
                    }
                },
                success: function(data){
                 
                        target.find('option.js-linecode').remove();
                        line.find('.fa-spin').remove();
                     
                        var html = '';
                        var response_len = 0;
                        for(var key in data.values) {
                            response_len++;
                        }
                        
                        if(response_len > 0){
                           // console.log(data.values);
                            for (var name in data.values) {
                                var value = (data.values[name] == null ? '' : data.values[name]);
                                html += '<option class="js-linecode" value="' + name + '">' + name + (value != '' ? '(' + value + ')' : '') + '</option>';
                                line.find('.fa-circle.fa-stack-2x').css('background-color', 'green');
                                line.find('.fa-stack .fa-stack-1x').html('').removeClass('fa-times').addClass('fa-check').removeClass('fa-inverse'); 
                                jQuery('.js-submit-button').prop('disabled', true);
                                line.find('.js-select-linecode').prop('disabled', false);
                                
                            }
                            target.append(html);
                            target.removeAttr('disabled');
                      
                    }
                }
               
            });
        }
    }

    jQuery('.js-select-linecode').on('change', function(){  
        var check_empty = 0;
        jQuery('.js-select-linecode option:selected').each( function(){
             if (jQuery('.js-select-linecode option:selected').val() == '') {
                check_empty++;
             }
                
            });
         if (check_empty > 0) {
                jQuery('.js-submit-button').prop('disabled', true);
             } else {
                jQuery('.js-submit-button').prop('disabled', false);
             }
      
    });

    jQuery('.js-speed-entry').on('submit', function(){
        return !preventSubmit;
    });

    /*jQuery(".js-upload").change(function (){
        jQuery('.js-upload-loading-icon').removeClass('hide');
        if (jQuery(".js-upload").val() == '') {
            return false;
        } else {
            jQuery(".js-file-import").val(jQuery(".js-upload").val());
            var form = jQuery(".js-speed-entry");
            form.submit();
        }
        jQuery('.js-upload-loading-icon').addClass('hide');
    });*/
    jQuery(".js-upload").on("click", function(event){
        event.preventDefault();
        jQuery(".js-file-import").trigger("click");
    });
    jQuery('.js-speed-entry').on('click', '.js-reset-button', function(){
        jQuery('.js-speed-entry').find('input[type=text]').val('');
        jQuery('.js-speed-entry').find('.js-select-linecode').val('');
        jQuery('.js-select-linecode').prop('disabled', true);
        jQuery('.js-speed-entry').find('input[type=number]').val('1');
        jQuery('.fa-circle.fa-stack-2x').css('background-color', 'transparent');
        jQuery('.js-submit-button').attr('disabled', 'disabled');
        var speed_entry_index = 1;
       jQuery('.fa-stack .fa-stack-1x').each(function(){ 
            jQuery(this).removeClass('fa-times').removeClass('fa-check').addClass('fa-inverse').html(speed_entry_index);
            speed_entry_index++;
       });
        
    }); 
    jQuery('.js-speed-entry').on('click', '.js-submit-button', function(){
        preventSubmit = false;
        var form = jQuery(this).closest('form');
        form.find('.js-import-loading-icon').removeClass('hide');
        jQuery(this).closest('form').submit();
    });
    jQuery('.js-speed-entry').on('click', '.js-import-file-button', function(){
        jQuery(this).closest('.js-speed-entry').find('.js-file-import').click();
    });
    jQuery('.js-speed-entry').on('change', '.js-file-import', function(){
        preventSubmit = false;
        var form = jQuery(this).closest('form');
        form.find('.js-import-loading-icon').removeClass('hide');
        form.submit();
    });
    jQuery('.js-car-import-file').on('click', function(){
       jQuery('.js-container-search-tool').removeClass('search-panel-original-height').addClass('search-panel-full-height');
    
    });
    jQuery('.js-tab:not(".js-car-import-file")').on('click', function(){
       jQuery('.js-container-search-tool').removeClass('search-panel-full-height').addClass('search-panel-original-height');

    });
  
     
});  

 
