jQuery(document).ready(function(){
    jQuery('.js-customer-type').click(function() {
        toggleEnterpriseFields();
    });

    function toggleEnterpriseFields() {
        if (jQuery('.js-radio-enterprise').is(':checked')) { 
            jQuery('.js-fields-enterprise').fadeIn();
         } else {
            jQuery('.js-fields-enterprise').fadeOut();
         }
    }
    toggleEnterpriseFields();

    function CountryList(el){
        var p = el.find(".js-province");
        var s = el.find(".js-state");
        var c = el.find(".js-country select")
        c.change(toggleStateList);
        function toggleStateList() {
            s.addClass('hide');
            p.addClass('hide');
            if (c.val() == 'CA') {
                p.removeClass('hide');
            } else if (c.val() == 'US') {
                s.removeClass('hide'); 
            }
        }
        toggleStateList()
        return this;
    }

    jQuery("[data-container=addresses]").each(function(){
        new CountryList(jQuery(this));
    })

    /*jQuery(".js-box-readonly").on("change",function(){
        inputs = jQuery(this).closest(".c--box").find("input,textarea,select")
        var c = jQuery(this).is(':checked');
        
        inputs.each(function(){
            var _ = this;
            var copyVal = function(){
                jQuery(_).attr(jQuery(_).is("select")?'disabled':'readonly', false).val(jQuery(this).val()).trigger("change").attr(jQuery(_).is("select")?'disabled':'readonly', true);
                if(jQuery(_).is("select")){
                    if(jQuery(_).next().is("input"))
                        jQuery(_).next().remove()
                    jQuery(_).after('<input type="hidden" name="'+jQuery(this).attr("name")+'" value="'+jQuery(this).val()+'">');
                }
            }
            if(c){
                var input = jQuery("[name='"+jQuery(this).attr("name").replace("profile_address[shipping_addresses_0_","profile_address[")+"']");
                if(input.length){
                    jQuery(this).val(input.val());
                    input.on('change',copyVal).trigger("change");
                }
                else if(jQuery(this).attr("name") == 'profile_address[shipping_addresses_0_name]'){
                    jQuery(this).val($("[name=\"profile_address[company]\"]").val());
                    $("[name=\"profile_address[company]\"]").on('change',copyVal).trigger("change");
                }
            }
            else{
                if(jQuery('select[name="'+jQuery(this).attr("name")+'"]').length && jQuery(this).is("input")){
                    jQuery(this).remove();
                }

                var input = jQuery("[name='"+jQuery(this).attr("name").replace("profile_address[shipping_addresses_0_","profile_address[")+"']");
                if(input.length){
                    input.off('change' ,copyVal);
                    console.log(input);
                }
                else if(jQuery(this).attr("name") == 'profile_address[shipping_addresses_0_name]'){
                    $("[name=\"profile_address[company]\"]").off('change');
                }
                jQuery(this).attr(jQuery(_).is("select")?'disabled':'readonly', false);
            }
        })
    })*/
    jQuery(".js-box-readonly").closest(".c--box").find("input,textarea,select").each(function(){
        var _ = this;
        var copyVal = function(){
            jQuery(_).attr(jQuery(_).is("select")?'disabled':'readonly', false).val(jQuery(this).val()).trigger("change").attr(jQuery(_).is("select")?'disabled':'readonly', true);
            if(jQuery(_).is("select")){
                if(jQuery(_).next().is("input"))
                    jQuery(_).next().remove()
                jQuery(_).after('<input type="hidden" name="'+jQuery(_).attr("name")+'" value="'+jQuery(this).val()+'">');
            }
        }

        jQuery(".js-box-readonly").on("change",function(){
            var c = jQuery(this).is(':checked');
            if(c){
                var input = jQuery("[name='"+jQuery(_).attr("name").replace("profile_address[shipping_addresses_0_","profile_address[")+"']");
                if(input.length){
                    input.on('change',copyVal).trigger("change");
                }
                else if(jQuery(_).attr("name") == 'profile_address[shipping_addresses_0_name]'){
                    $("[name=\"profile_address[company]\"]").on('change',copyVal).trigger("change");
                }
            }
            else{
                if(jQuery(_).is("select") && jQuery(_).next().is("input")) jQuery(_).next().remove();

                var input = jQuery("[name='"+jQuery(_).attr("name").replace("profile_address[shipping_addresses_0_","profile_address[")+"']");
                if(input.length){
                    input.off('change', copyVal);
                }
                else if(jQuery(_).attr("name") == 'profile_address[shipping_addresses_0_name]'){
                    $("[name=\"profile_address[company]\"]").off('change', copyVal);
                }
                jQuery(_).attr(jQuery(_).is("select")?'disabled':'readonly', false);
            }
        });
    });

    jQuery(".js-box-readonly").change();

});