var mappings = {step2 : {'manufacturers' : [], 'ids': {}, 'jobs':{}, 'hierarchy' : {}}, step3 : {'filters' : [], 'parts' : []}};
var isFiltering = false;
var logicalFilter = [];
var hasExpanded = false;
jQuery('document').ready(function(){
    setTimeout(function(){
        $('.js-reset').click();


    },100);

    if($('.js-btn-weathertech').length){
        var app = new WeatherTech($('.js-btn-weathertech').data('year'),$('.js-btn-weathertech').data('make'),$('.js-btn-weathertech').data('model'));
        $('.js-btn-weathertech').on('click',function(){
            app.show();
        })
    }
    
    /*****************************************************
     * STEP 2
     ****************************************************/
 
    /**
     * Maps the submenu events of the cateogries list (opens/closes dropdown)
     */
     if (Tools.IsMobile())
    {
        $('.js-select2').removeAttr('multiple');
    }

    $('[data-goto-parttype]').click(function(){
            var partType = $(this).attr('data-goto-parttype');
            $('#ddlPartty1pe').val(partType);
            $('#ddlPartty1pe').change();
    });
    $('[data-goto-job]').click(function(){
            var job = $(this).attr('data-goto-job');
            $('#ddlJobs').val(job);
            $('#ddlJobs').change();
    });

    jQuery('.js-step2, .js-step3').on('click', '.js-submenu', function(){
        var target = jQuery(this).parent().find(jQuery(this).data('target'));
        var icon = jQuery(this).find('.js-icon');
        
        if (target.hasClass('js-closed'))
        {
            target.slideDown('fast', function(){
                jQuery(this).removeClass('js-closed');
            });
            icon.removeClass('fa-angle-right');
            icon.addClass('fa-angle-down');
        }
        else
        {
            target.slideUp('fast', function(){
                jQuery(this).addClass('js-closed');
            });
            icon.removeClass('fa-angle-down');
            icon.addClass('fa-angle-right');
        }
    });
    jQuery('.js-step2 .js-categories, .js-step3 .js-categories').on('click', 'input[type=checkbox]', function(event){
        event.stopPropagation();
    });
    
    /**
     * Binds the manufacturers with the categories
     */
    jQuery('.js-step2').find('.js-searchmap').each(function(){
        var data = jQuery(this).val();
        data = atob(data);
        data = jQuery.parseJSON(data);
        
        mappings.step2.manufacturers.push(data);
    });
    jQuery('.js-step2').find('.js-namemap').each(function(){
        var data = jQuery(this).val();
        data = atob(data);
        data = jQuery.parseJSON(data);
        
        mappings.step2.ids = data;
    });
    jQuery('.js-step2 .js-jobs-select').find('option').each(function(){
        var data = jQuery(this).attr('data-mappings');
        if (data) {
            data = atob(data);
            data = jQuery.parseJSON(data);
            mappings.step2.jobs[jQuery(this).val()] = data;
        }
    });
    jQuery('.js-step2 .js-hierarchy').each(function(){
        var data = jQuery(this).val();
        if (data) {
            data = atob(data);
            data = jQuery.parseJSON(data);
            mappings.step2.hierarchy = data;
        }
    });

    jQuery('.js-step2 .js-categories').on('change', '.js-chk', function (){
        var checked = jQuery(this).is(':checked');
        jQuery(this).closest('.js-container').find('.js-chk').prop('checked', checked);
        jQuery(this).closest('.js-container').find('.js-chk[data-type=manufacturer]').each(function(){
            jQuery('.js-step2 .js-manufacturers-list .js-manufacturer .js-chk[value="' + jQuery(this).val() + '"]').prop('checked', checked);
        });
        var shouldEnable = false;
        jQuery(this).parents('.js-container').each(function(){
            var checkbox = jQuery(this).find('.js-submenu').first().find('.js-chk');
            var indetermined = false;
            jQuery(this).find('.js-container').find('.js-chk').each(function(){
                if (jQuery(this).is(':checked') != checked)
                {
                    indetermined = true;
                }
            });
            checkbox.prop('checked', checked);
            checkbox.prop('indeterminate', indetermined);
            if (checked || indetermined) {
                shouldEnable = true;
                jQuery(this).find('.js-submenu').first().addClass('chk-selected');
            } else {
                jQuery(this).find('.js-submenu').first().removeClass('chk-selected');
            }
        });
        if (shouldEnable) {
            $('.js-step2 .js-getresults, .js-step2-mobile .js-getresults').removeClass('disabled');
            $('.js-step2 .js-reset, .js-step2-mobile .js-reset').removeClass('disabled');
        } else {
            $('.js-step2 .js-getresults, .js-step2-mobile .js-getresults').addClass('disabled');
             $('.js-step2 .js-reset, .js-step2-mobile .js-reset').addClass('disabled');
        }
    });
    jQuery('.js-step2 .js-manufacturers-list').on('change', '.js-chk', function(){
        item_check =  jQuery(this);
        itemCheck(item_check); 
    });
    jQuery('.js-step2 .js-manufacturers-list').on('click', '.js-brand-item', function(){ 
      item_check =  jQuery(this).siblings('.js-chk');
     item_check.prop("checked", !item_check.prop("checked"));
      itemCheck(item_check);
    }); 
 
    function itemCheck(item_check){
        var checked = item_check.is(':checked');
        jQuery('.js-step2 .js-categories .js-manufacturer .js-chk[value="' + item_check.val() + '"]').each(function(){
            jQuery(this).prop('checked', checked);
        });
        
        updateStep2FiltersState();
    } 

    /**
     * Part types list selection that will check items based on the user's selection
     */
    jQuery('.js-parttype-select').on('change', function(){
        var value = jQuery(this).val();
        
        if (value != '')
        {
            jQuery('.js-step2 .js-categories .js-chk[value="' + value + '"]').each(function(){
                jQuery(this).prop('checked', true);
                jQuery(this).change();
                jQuery(this).parents('.js-container').each(function(){
                    if (jQuery(this).find('.js-level').first().hasClass('js-closed')){
                        jQuery(this).find('.js-submenu').first().click();
                    }
                });
            });
            var ddl = jQuery(this);
            setTimeout(function() {
                ddl.find('option').prop('selected', false);
                ddl.find('option[value=""]').prop('selected', true);
                ddl.change();
            }, 50);
        }
    });
  
    jQuery('.js-step2 .js-manufacturers-list').on('click', '.js-container-droplist-part-type', function(event){

    });
    function expandSection(sectionData,sectionId){

        $('['+sectionData+']').removeClass('active');
        $('['+sectionData+'="'+sectionId+'"]').addClass('active');

    }
    function closeSection(sectionData,sectionId){

        $('['+sectionData+'="'+sectionId+'"]').removeClass('active');

    }


    $('[data-manufacturer-button]').click(function(){
        var keyName=$(this).attr('data-manufacturer-button');
        var htmlContent=$('[data-displayer-manufacturer="'+keyName+'"]').html();
        var sixId=$(this).attr('data-ten-row-manufactirer-displayer-button-id');
        var threeId=$(this).attr('data-six-row-manufactirer-displayer-button-id');
        var twoId=$(this).attr('data-three-row-manufactirer-displayer-button-id');
        var parttypes=$(this).attr('data-parttypes');

        $("[data-ten-row-manufactirer-displayer-id='"+sixId+"']").html("<i class=\"fa fa-circle-o-notch fa-spin fa-fw\"></i>");
        $("[data-six-row-manufactirer-displayer-id='"+threeId+"']").html("<i class=\"fa fa-circle-o-notch fa-spin fa-fw\"></i>");
        $("[data-three-row-manufactirer-displayer-id='"+twoId+"']").html("<i class=\"fa fa-circle-o-notch fa-spin fa-fw\"></i>");

        var isActive=$(this).hasClass('selected');

        if(isActive){
            $('[data-manufacturer-button]').removeClass('selected');
        }
        else{
            $('[data-manufacturer-button]').removeClass('selected');
            $('[data-manufacturer-button="'+keyName+'"]').addClass('selected');
        }




        if(isActive){
            closeSection('data-ten-row-manufactirer-displayer-id',sixId);
            closeSection('data-six-row-manufactirer-displayer-id',threeId);
            closeSection('data-three-row-manufactirer-displayer-id',twoId);
        }
        else{
            expandSection('data-ten-row-manufactirer-displayer-id',sixId);
            expandSection('data-six-row-manufactirer-displayer-id',threeId);
            expandSection('data-three-row-manufactirer-displayer-id',twoId);
        }





        //setTimeout(function(){$("[data-manufacturer-button='"+keyName+"']").find('.js-container-droplist-part-type').click();},1000)
        $("[data-manufacturer-button='"+keyName+"']").find('.js-container-droplist-part-type').each(function(){

            dropdown_icon = jQuery(this).find('.js-dropdown-icon');

            htmlContent = showPartType(event, dropdown_icon,$());

        });

        $("[data-ten-row-manufactirer-displayer-id='"+sixId+"']").html(htmlContent);
        $("[data-six-row-manufactirer-displayer-id='"+threeId+"']").html(htmlContent);
        $("[data-three-row-manufactirer-displayer-id='"+twoId+"']").html(htmlContent);

        var minHeight=0;
        var elemHeight;
        /*$("[data-ten-row-manufactirer-displayer-id='"+sixId+"']").find('.displayer__content__manufacturer__parttype').each(function(){
            elemHeight=$(this).height();
            if(elemHeight>minHeight)minHeight=elemHeight;
            $("[data-ten-row-manufactirer-displayer-id='"+sixId+"']").find('.displayer__content__manufacturer__parttype').css('min-height',(minHeight+22)+'px')
        });
        var minHeight=0;
        var elemHeight;
        $("[data-six-row-manufactirer-displayer-id='"+threeId+"']").find('.displayer__content__manufacturer__parttype').each(function(){
            elemHeight=$(this).height();
            if(elemHeight>minHeight)minHeight=elemHeight;
            $("[data-six-row-manufactirer-displayer-id='"+threeId+"']").find('.displayer__content__manufacturer__parttype').css('min-height',(minHeight+22)+'px')
        });
        var minHeight=0;
        var elemHeight;
        $("[data-three-row-manufactirer-displayer-id='"+twoId+"']").find('.displayer__content__manufacturer__parttype').each(function(){
            elemHeight=$(this).height();
            if(elemHeight>minHeight)minHeight=elemHeight;
            $("[data-three-row-manufactirer-displayer-id='"+twoId+"']").find('.displayer__content__manufacturer__parttype').css('min-height',(minHeight+22)+'px')
        });*/


        initSubManJS();
        //htmlContent = getPartsContent(parttypes);

    });

    function initSubManJS(){

        $('[data-part-type-id]').click(function(){
            idTriger=$(this).attr('data-part-type-id');
            $('.js-dropdown-manufacturer').find("[value='"+idTriger+"']").click();
            setTimeout(function(){
                $('.js-getresults').click();
            },100)

        });

    }
    $('.js-parttype-select').change(function(){
        setTimeout(function(){
            $('.js-getresults').click();
        },100)
    });
    $('.js-jobs-select').change(function(){
        setTimeout(function(){
            $('.js-getresults').click();
        },100)
    });
    function showPartType(event, dropdown_icon){
         event.stopPropagation();
        jQuery('.js-dropdown-manufacturer').remove();
        jQuery('.js-parttype-pop-header, .js-parttype-pop-footer').css('display', 'none');
        var name = dropdown_icon.closest('.js-manufacturer').find('.js-chk').first().val();
        var original = jQuery('.js-step2 .js-dropdown-template');
        var panel = original.clone();
        var currentPos = dropdown_icon.offset();
        var framePos = {x:currentPos.left - original.width() + 15, y:currentPos.top - 150};
        var diff_posY = window.innerHeight - (currentPos.top - jQuery(window).scrollTop());
        var diff_posX = window.innerWidth - (currentPos.left - jQuery(window).scrollLeft());
        var header_width = '500px';
        
        if (jQuery(window).width() > 768) {
             panel_left = framePos.x;
             if (diff_posY < 400) {
                panel_top = framePos.y - 430;
             } else {
                panel_top = framePos.y + 50;
             }
        } else if(jQuery(window).width() > 480) { 
            if (diff_posX > 270) {
                panel_left = framePos.x + 348;
             } else {
                panel_left = framePos.x;
             }
             if (diff_posY < 300) {
                panel_top = framePos.y - 460;
             } else {
                panel_top = framePos.y - 20;
             }

        } else {
            panel_top = jQuery(window).scrollTop() + 50;
            panel_left = 20;
            header_width = '85%';
          
        }

       

        panel.css('top', panel_top + 'px');
        panel.css('left', panel_left + 'px');
       
        panel.removeClass('js-dropdown-template');
        panel.addClass('js-dropdown-manufacturer');
        var rowTemplate = panel.find('.js-row-template');
        rowTemplate.removeClass('js-row-template');
        var data = [];

        for(var key in mappings.step2.manufacturers)
        {
            if (mappings.step2.manufacturers[key].name == name)
            {
                for(var i = 0; i < mappings.step2.manufacturers[key].info.pt.length; i++)
                {
                    for(var ptName in mappings.step2.ids)
                    {
                        if (mappings.step2.ids[ptName] == mappings.step2.manufacturers[key].info.pt[i])
                        {
                            data.push({'name' : ptName.replace('pt-', ''), 'id' : mappings.step2.ids[ptName]});
                        }
                    }
                }
            }
        }

        data.sort(function(a, b){return a.name.localeCompare(b.name);});
        var htmlContent='';
        for(var i = 0; i < data.length; i++)
        {
            var row = rowTemplate.clone();
            var checkbox = row.find('.js-dropdown-manufacturer-chk');
            var label = row.find('.js-name');
            checkbox.val(data[i].id);
            checkbox.attr('data-name', name);
            checkbox.attr('id', data[i].name);
            label.attr('for', data[i].name);
            checkbox.prop('checked', jQuery('.js-step2 .js-categories .js-parttype .js-chk[value="' + data[i].id + '"]').closest('.js-container').find('.js-manufacturer').find('.js-chk[value="' + name + '"]').is(':checked'));
            row.find('.js-name').html(data[i].name);
            htmlContent+="<div class='displayer__content__manufacturer__parttype' data-part-type-id='"+data[i].id+"'>"+data[i].name+"</div>";
            panel.append(row).hide().fadeIn(200);







            // var row = rowTemplate.clone();
            // var checkbox = row.find('.js-dropdown-manufacturer-chk');
            // var label = row.find('.js-name');
            // checkbox.val(data[i].id);
            // checkbox.attr('data-name', name);
            // checkbox.attr('id', data[i].name);
            // label.attr('for', data[i].name);
            // checkbox.prop('checked', jQuery('.js-step2 .js-categories .js-parttype .js-chk[value="' + data[i].id + '"]').closest('.js-container').find('.js-manufacturer').find('.js-chk[value="' + name + '"]').is(':checked'));
            // row.find('.js-name').html(data[i].name);
            // panel.append(row).hide().fadeIn(200);
        }
        rowTemplate.remove();

        part_type_header = jQuery('.parttype-pop-header');
        part_type_header.addClass('js-parttype-pop-header');
        part_type_header.css('left', panel_left + 'px');
        part_type_header.css('top', panel_top - 40 + 'px');
        part_type_header.css('position', 'absolute');
        part_type_header.css('z-index', '99');
        part_type_header.css('background-color', '#DCDCDC');
        part_type_header.css('width',  header_width); 
        part_type_header.css('padding',  '10px');
        part_type_header.find('h4').css('display', 'inline-block');
        part_type_header.find('.slick-lightbox-close').css({'top' : '-5px', 'width' : '0', 'height' : '0', 'right' : '40px'});
        part_type_header.find('.slick-lightbox-close').addClass('override-color-gray');

        part_type_footer = jQuery('.parttype-pop-footer'); 
        part_type_footer.addClass('js-parttype-pop-footer');
        part_type_footer.css('left', panel_left + 'px');
        part_type_footer.css('top', panel_top + 300 + 'px');
        part_type_footer.css('position', 'absolute');
        part_type_footer.css('z-index', '99');
        part_type_footer.css('background-color', '#eb2131');
        part_type_footer.css('color', '#fff');
        part_type_footer.css('font-weight', 'bold');
        part_type_footer.css('width',  header_width); 
        part_type_footer.css('padding',  '10px');
        part_type_footer.css('text-align', 'center');
        part_type_footer.css('cursor', 'pointer');
        
        part_type_header.css('display',  'none');
        part_type_footer.css('display',  'none');
        jQuery('.js-step2').append(panel).before(part_type_header.fadeIn(200));
        jQuery('.js-step2').append(panel).before(part_type_footer.fadeIn(200));

       // if (Tools.IsMobile())
           // jQuery('.js-mobile-overlay').fadeIn();
        return htmlContent;
    }

    $('#customer-business').click(function(){

    });

    jQuery('.js-step2').on('change', '.js-dropdown-manufacturer-chk', function(){
        var id = jQuery(this).val();
        var name = jQuery(this).data('name');
        var checkbox = jQuery('.js-step2 .js-categories .js-parttype .js-chk[value="' + id + '"]').closest('.js-container').find('.js-manufacturer').find('.js-chk[value="' + name + '"]');

        if ($(this).parent().parent().find('input[type="checkbox"]:checked').length == 0) {
            checkbox.prop('checked', false);
        } else {
            checkbox.prop('checked', true);
        
        }
       
        checkbox.change();
    });
    jQuery('body').on('click', '.js-dropdown-manufacturer', function(event){
        event.stopPropagation();
    });
    jQuery('body').on('click', function(e){
        if(!$(e.target).closest('.nav__container,.js-header-modal,.js-toggle-menu,.c-smart-search').length){
            jQuery('.js-parttype-pop-header, .js-parttype-pop-footer, .js-dropdown-manufacturer, .js-mobile-overlay').fadeOut();
            jQuery('.js-nav__container').removeClass('is-open');
            jQuery('.js-nav__container,.js-toggle-menu').removeClass('is-open')
            jQuery('.js-container-partial--my-garage, .js-container-partial--help, .js-container-speed-entry, .js-container-partial--subscription').fadeOut();
        }
       
    });

    /**
     * Job change actions
     */
    jQuery('.js-step2').on('change', '.js-jobs-select', function(){
        var val = jQuery(this).val();
        for(var key in mappings.step2.jobs[val])
        {
            var chk = jQuery('.js-step2 .js-chk[data-type=parttype][value=' + mappings.step2.jobs[val][key] + ']');
            chk.prop('checked', true);
            chk.change();
        }
        jQuery(this).find('option').prop('selected', false);
        jQuery(this).find('option[value=""]').prop('selected', true);
    });
    
    /**
     * Resets all the checkboxes for the step2
     */
    jQuery('.js-step2, .js-step2-mobile').on('click', '.js-reset', function(){
        jQuery('.js-step2 .js-chk').prop('checked', false).prop('indeterminate', false);
        jQuery('.js-step2 .js-categories .js-submenu.chk-selected').removeClass('chk-selected');
        jQuery('.js-step2 .js-reset, .js-step2 .js-getresults,  .js-step2-mobile .js-getresults, .js-step2-mobile .js-reset').addClass('disabled');
        jQuery('.js-subcategories, .js-parttypes, .js-manufacturers').addClass('js-closed').css('display', 'none').parent();
        var icon = jQuery('.js-submenu').find('.js-icon');
        icon.removeClass('fa-angle-down');
        icon.addClass('fa-angle-right');
        
    });
    
    jQuery('.js-step2').on('change', '.js-na-check', function(){
        if (jQuery(this).prop('checked')) {
            setTimeout(function(){
                Tools.InsertParam('nap', '');
            }, 150);
        } else {
            setTimeout(function(){
                Tools.RemoveParam('nap');
            }, 150);
        }
    });
    
    /*****************************************************
     * STEP 3
     ****************************************************/
    /**
     * Binds the filters with the parts
     */
    jQuery('.js-step3').find('.js-mapping').each(function(){
        var data = jQuery(this).val();
        data = atob(data);
        data = jQuery.parseJSON(data);
        
        mappings.step3.filters.push(data);
    });
    /**
     * Binds the parts with the filters
     */
    jQuery('.js-step3').find('.js-part').each(function(){
        mappings.step3.parts[jQuery(this).attr('id')] = {
            mappings: jQuery(this).attr('data-ids'),
            visible: true,
            parttype: jQuery(this).attr('data-parttype')
        };
    });
    /**
     * Visual dropdowns of fits on
     */
    jQuery('.js-step3').on('click', '.js-fit-header', function(){
        var subfit = jQuery(this).closest('.js-fit').find('.js-sub-fit').first();
        var icon = jQuery(this).find('.js-icon-fit');
        
        if (subfit.hasClass('js-closed'))
        {
            subfit.slideDown('fast', function(){
                jQuery(this).removeClass('js-closed');
            });
            icon.removeClass('fa-angle-right');
            icon.addClass('fa-angle-down');
        }
        else
        {
            subfit.slideUp('fast', function(){
                jQuery(this).addClass('js-closed');
            });
            icon.removeClass('fa-angle-down');
            icon.addClass('fa-angle-right');
        }
    });
     /**
     * Toggle filter panel mobile
     */
    jQuery('.js-step3').on('click', '.js-btn-filter', function(){
        jQuery('.left-side').addClass("is-open"); 
        var element = $('<div class="mobile-overlay" style="display: none;"></div>');
        element.on('click',function(){
            jQuery('.left-side').removeClass("is-open"); 
            element.fadeOut("normal", function() {$(this).remove();});
        })
        $('#main').append(element);
        element.fadeIn();
        /*jQuery('.js-mobile-overlay').off().fadeIn();*/
    }); 

    /**
     * accordeon step3
    */
    jQuery('.js-noprice-check').on('click', function(){
        applyStep3Filters();
    });
    /**
     * Display parts
    */
    jQuery(".js-parttype-header").on('click', function(){
        SortPartsBy.sort(this.parentElement);
        if (jQuery('.js-noprice-check').attr('checked') == 'checked') {
            jQuery(this).siblings('.js-part').slideToggle();
        } else {
            jQuery(this).siblings('.js-part:not([data-unitprice="0"])').slideToggle();
        }
       
       jQuery(this).find('.fa').toggleClass('fa-plus-circle fa-minus-circle');
       
    }); 


    jQuery('.js-btn-moreinfo').on('click', function(e) {
       e.preventDefault();
       var link = jQuery(this).data('link');
       if (e.ctrlKey){
            window.open(link,'_blank')
       }else{
            window.location.href = link;
       }
    });

    /**
     * Add to cart action
     */
    jQuery('.js-step3').on('click', '.js-btn-addtocart', function(){
        var url = document.getElementById('addtocart').value;
        var year = document.getElementById('year').value;
        var make = document.getElementById('make').value;
        var model = document.getElementById('model').value;
        var part = jQuery(this).closest('.js-part');
        var quantityControl = part.find('.js-txt-quantity');
        var manufacturer = part.data('manufacturer');
        var description = part.data('description');
        var description = part.data('description');
        var parttypename = part.data('parttypename');
        var productCode = part.data('partnumber');
        var lineCode = part.data('linecode');
        var buttonClicked = jQuery(this);
        
        quantityControl.removeClass('error');
        if (quantityControl.val() == 0) {
            quantityControl.addClass('error');
            return;
        }
        
        var overlay = part.find('.js-part-overlay');
        var overlayLoading = part.find('.js-part-overlay-loading');
        var overlayMessage = part.find('.js-part-overlay-message');
        var overlaySuccess = overlayMessage.find('.js-success');
        var overlayError = overlayMessage.find('.js-error');
        overlaySuccess.removeClass('hide');
        overlayError.addClass('hide');
        
        overlay.removeAttr('style');
        overlayLoading.removeAttr('style');
                    
        $.ajax({
            url:url,
            dataType: 'JSON',
            type: 'POST',
            data: {
                quantity : quantityControl.val(),
                productcode : productCode,
                linecode : lineCode,
                year : year,
                make : make,
                model : model,
                manufacturer : manufacturer,
                description : description,
                parttype : parttypename
            },
            success: function(data){
                if (data.error) {
                    overlaySuccess.addClass('hide');
                    overlayError.removeClass('hide');
                }  else if (data.count > 0) {
                    jQuery('.js-menu-cart-count').html( data.count).removeClass('hide').parent().find('.fa').addClass('fa-red');;
                    jQuery('.js-breadcrumb-cart-count').html(data.count);
                    jQuery('.js-breadcrumb-cart-text-single, .js-breadcrumb-cart-text-multiple').addClass('hide');
                    if(data.count > 1) {
                        jQuery('.js-breadcrumb-cart-text-multiple').removeClass('hide');
                    }else{
                        jQuery('.js-breadcrumb-cart-text-single').removeClass('hide');  
                    }
                    
                    buttonClicked.css('background' , '#313236').css('background-color' , '#313236'); 
                    buttonClicked.siblings('.item-added').removeClass('hide'); 
                }
                overlayLoading.css('display', 'none');
                overlayMessage.removeAttr('style');
                setTimeout(function(){
                    overlay.animate({
                        opacity:0
                    }, 300, function(){
                        setTimeout(function(){overlay.css('display', 'none');}, 250);
                    });
                    overlayMessage.animate({
                        opacity:0
                    }, 300, function(){
                        setTimeout(function(){overlayMessage.css('display', 'none');}, 250);
                    });
                },2000); 
            }
        });
    });

    /**
     * step 3 sticky header
     */
    jQuery(window).bind('scroll', function() {
        jQuery('.js-parttype-header:visible').each(function() {
            var header = jQuery(this);
            var position = header.offset().top - jQuery(window).scrollTop();
            var sticky_header_title = header.attr('data-title');
            var header_width = jQuery('.right-side').width();
            var top = window.innerWidth>=1070?145:0;
            if(jQuery(window).scrollTop()+top > jQuery('.js-parttype-header:first-of-type:visible').offset().top && jQuery(window).scrollTop()+top+50 < jQuery('footer').offset().top){
                jQuery(".js-parttype-sticky-header").fadeIn();
                if (position <= 0) {
                   jQuery('.js-parttype-sticky-header').text(sticky_header_title);
                   jQuery(".js-parttype-sticky-header").css({
                       'width': header_width,
                       'top' : top+"px"
                    }); 
                }

            } else {
                 jQuery(".js-parttype-sticky-header").fadeOut();
                
            } 
        });        
    });

    /**
     * toogle anchor top button
     */
     jQuery(document).scroll(function(){
        jQuery('.js-dropdown-manufacturer, .js-parttype-pop-header, .js-parttype-pop-footer, .js-mobile-overlay').fadeOut();
        //jQuery('.js-nav__container,.js-toggle-menu').removeClass('is-open');
        if (jQuery(this).scrollTop() > 500) {
            jQuery('.js-scroll-to-top').fadeIn();
        } else {
            jQuery('.js-scroll-to-top').fadeOut(); 
        }

    });
     /**
     * Scroll to top
     */
    jQuery('.js-scroll-to-top').click(function(){
        jQuery('html, body').animate({scrollTop : 0}, 800);
        return false;
    });
    /**
     * Reset filters
     */
    jQuery('.js-step3').on('click', '.js-reset', function(){
        if (isFiltering) {
            return false;
        }
        isFiltering = true;
        jQuery('.js-step3 .js-categories .js-chk').prop('checked', false);
        logicalFilter = [];
        applyStep3Filters();
        isFiltering = false;
    });
    
    /**
     * Filter checkboxes actions
     */
    jQuery('.js-step3').on('change', '.js-chk', function(){
        if (isFiltering) {
            return false;
        }
        isFiltering = true;

        var category = jQuery(this).attr('data-category');
        var id = parseInt(jQuery(this).attr('id').replace('filter-', ''));
        if ($(this).prop('checked')) {
            var filter = {};
            strKeys = Object.keys(logicalFilter);
            intKeys = strKeys.map(Number);
            lastKey = (intKeys.length > 0 ? intKeys[intKeys.length - 1] : -1);
            if (lastKey >= 0 && logicalFilter[lastKey].category == category) {
                filter = logicalFilter[lastKey];
                filter.filters.push(id);
                logicalFilter[lastKey] = filter;
            } else {
                filter.category = category;
                filter.filters = [id];
                logicalFilter.push(filter);
            }
        } else {
            var toDelete = [];
            for (var i in logicalFilter)
            {
                for(var j in logicalFilter[i].filters)
                {
                    if (id == logicalFilter[i].filters[j]) {
                        delete logicalFilter[i].filters[j];
                    }
                }
                var count = 0;
                for(var j in logicalFilter[i].filters) {
                    count++;
                }
                if (count == 0) {
                    toDelete.push(i);
                }
            }
            for (var i in toDelete)
            {
                delete logicalFilter[toDelete[i]];
            }
        }
        
        var loadingIcon = jQuery(this).closest('.js-filter').find('.js-loading-icon');
        loadingIcon.removeClass('hide');
        
        setTimeout(function(){
            if (!hasExpanded) {
                expandAll();
                hasExpanded = true;
            }
            applyStep3Filters(isFiltering);
            loadingIcon.addClass('hide');
            isFiltering = false;
        }, 150);

    });
    jQuery('.js-step3 .js-filter').on('click', 'input[type=checkbox]', function(){
        if (jQuery(this).closest('.js-filter').hasClass('disabled') || isFiltering) {
            return false;
        }
    });
    jQuery('.js-step3 .js-filter').on('click', 'label', function(){
        if (jQuery(this).closest('.js-filter').hasClass('disabled') || isFiltering) {
            return false;
        }
    });
    applyStep3Filters();
    
    jQuery('.js-loading-overlay').addClass('hide');
    /****************************************************/
    /**
    * sticky stuff
    */
        var  $el = jQuery('.js-fixed-element');

       if(jQuery(window).width() > 480){
            var $elPosY = $el.offset().top;
       }else{
            var $elPosY = $el.offset().top - 50;
       }

       jQuery(window).scroll(function(e){

           var isPositionFixed = ($el.css('position') == 'fixed');

             if (jQuery(this).scrollTop() > $elPosY && !isPositionFixed  && !Tools.IsMobile()){ 
               $el.addClass('fixed-element'); 
             }
             if (jQuery(this).scrollTop() < $elPosY && isPositionFixed && !Tools.IsMobile()) {
               $el.removeClass('fixed-element'); 
             } 
       });

   

});

/**
 * Updates the stat of all filters and their parent on step2
 */
function updateStep2FiltersState()
{
    var enableNextStep = false;
    var counts = {};
    for (var cid in mappings.step2.hierarchy){
        for (var scid in mappings.step2.hierarchy[cid]) {
            for (var ptid in mappings.step2.hierarchy[cid][scid]) {
                for (var m in mappings.step2.hierarchy[cid][scid][ptid]) {
                    var manufacturer = document.getElementById('manufacturer-' + cid + '-' + scid + '-' + ptid + '-' + mappings.step2.hierarchy[cid][scid][ptid][m]);
                    if (manufacturer) {
                        if (!counts[cid]) {
                            counts[cid] = {'checked' : 0, 'all' : 0};
                        }
                        if (!counts[cid + '-' + scid]) {
                            counts[cid + '-' + scid] = {'checked' : 0, 'all' : 0};
                        }
                        if (!counts[cid + '-' + scid + '-' + ptid]) {
                            counts[cid + '-' + scid + '-' + ptid] = {'checked' : 0, 'all' : 0};
                        }
                        var checked = (manufacturer.checked ? 1 : 0);
                        counts[cid].all++;
                        counts[cid].checked += checked;
                        counts[cid + '-' + scid].all++;
                        counts[cid + '-' + scid].checked += checked;
                        counts[cid + '-' + scid + '-' + ptid].all++;
                        counts[cid + '-' + scid + '-' + ptid].checked += checked;
                        if (checked != 0) {
                            enableNextStep = true;
                        }
                    }
                }
                var ptElement = document.getElementById('filter-pt-' + cid + '-' + scid + '-' + ptid);
                var ptSubmenuElement = document.getElementById('filter-pt-submenu-' + cid + '-' + scid + '-' + ptid);
                if (counts[cid + '-' + scid + '-' + ptid].checked > 0) {
                    ptSubmenuElement.classList.add('chk-selected');
                } else {
                    ptSubmenuElement.classList.remove('chk-selected');
                }
                ptElement.checked = counts[cid + '-' + scid + '-' + ptid].checked == counts[cid + '-' + scid + '-' + ptid].all;
                ptElement.indeterminate = counts[cid + '-' + scid + '-' + ptid].checked != counts[cid + '-' + scid + '-' + ptid].all && counts[cid + '-' + scid + '-' + ptid].checked > 0;
            }
            var scElement = document.getElementById('filter-sc-' + cid + '-' + scid);
            var scSubmenuElement = document.getElementById('filter-sc-submenu-' + cid + '-' + scid);
            if (counts[cid + '-' + scid].checked > 0) {
                scSubmenuElement.classList.add('chk-selected');
            } else {
                scSubmenuElement.classList.remove('chk-selected');
            }
            scElement.checked = counts[cid + '-' + scid].checked == counts[cid + '-' + scid].all;
            scElement.indeterminate = counts[cid + '-' + scid].checked != counts[cid + '-' + scid].all && counts[cid + '-' + scid].checked > 0;
        }
        var cSubmenuElement = document.getElementById('filter-c-submenu-' + cid);
        if (counts[cid].checked > 0) {
            cSubmenuElement.classList.add('chk-selected');
        } else {
            cSubmenuElement.classList.remove('chk-selected');
        }
    }
    
    if (enableNextStep) {
        jQuery('.js-step2 .js-reset, .js-step2-mobile .js-reset').removeClass('disabled');
        jQuery('.js-step2 .js-getresults, .js-step2-mobile .js-getresults').removeClass('disabled');
    } else {
        jQuery('.js-step2 .js-reset, .js-step2-mobile .js-reset').addClass('disabled');
        jQuery('.js-step2 .js-getresults, .js-step2-mobile .js-getresults').addClass('disabled');
    }
}

/**
 * Hides/shows results based on the filters chosen on step3
 * @returns {Boolean} If the filters were applied
 */
function applyStep3Filters()
{
    //PartType count
    var ptCount = [];
    for (var i in mappings.step3.parts)
    {
        var partType = mappings.step3.parts[i].parttype;
        if (!(partType in ptCount)) {
            ptCount[partType] = 0;
        }
        ptCount[partType]++;
    }
    
    //Cloning mappings
    var dataLeft = {};
    for(var i in mappings.step3.parts)
    {
        dataLeft[i] = mappings.step3.parts[i];
    }
    
    //Logically filtering data
    var strKeys = Object.keys(logicalFilter);
    var intKeys = strKeys.map(Number);
    var lastKey = (intKeys.length > 0 ? intKeys[intKeys.length - 1] : -1);
    var currentPtCount = [];
    var total_parts_count = 0;
    
    for(var i in logicalFilter)
    {
        var mappingIds = [];
        for (var j in logicalFilter[i].filters)
        {
            var filterId = logicalFilter[i].filters[j] - 1;
            for (var k in mappings.step3.filters[filterId].mappings)
            {
                mappingIds.push(mappings.step3.filters[filterId].mappings[k]);
            }
        }
        if (i == lastKey) {
            for (var j in mappings.step3.filters) {
                if (mappings.step3.filters[j].category == logicalFilter[lastKey].category) {
                    var filterId = parseInt(j) + 1;
                    if (!(filterId in currentPtCount)) {
                        currentPtCount[filterId] = [];
                    }
                    for (var k in dataLeft) {
                        for (var l in mappings.step3.filters[j].mappings) {
                            if (dataLeft[k].mappings.indexOf(mappings.step3.filters[j].mappings[l]) >= 0) {
                                if (!(k in currentPtCount[filterId])) {
                                    currentPtCount[filterId][k] = dataLeft[k];
                                }
                                break;
                            }
                        }
                    }
                }
            }
        }
        for (var j in dataLeft)
        {
            var isIn = false;
            for (var k in mappingIds)
            {
                if (dataLeft[j].mappings.indexOf(mappingIds[k]) >= 0) {
                    isIn = true;
                    break;
                }
            }
            if (!isIn) {
                delete dataLeft[j];
            }
        }
    }

    // ADDITIONNAL FILTER NOT PIES
    for (var partId in dataLeft)
    {
        el = document.getElementById(partId);
        if(!$('.js-noprice-check').is(':checked') && el.dataset.unitprice=="0"){
            delete dataLeft[partId];
        }
    }
    
    //Display data
    for (var partId in mappings.step3.parts)
    {
        if (partId in dataLeft) {
            document.getElementById(partId).classList.remove("hide");
        } else {
            document.getElementById(partId).classList.add("hide");
            ptCount[mappings.step3.parts[partId].parttype]--;
        }
    }
    for (var parttype in ptCount)
    {
        if (ptCount[parttype] <= 0) {
            document.getElementById(parttype).classList.add("hide");
        } else {
            document.getElementById(parttype).classList.remove("hide");
        }
        document.getElementById('headercount-' + parttype).innerHTML = '(' + ptCount[parttype] + ')';
    }
    jQuery('.js-total-parts-count').html(total_parts_count);
    part_type_count = jQuery('.js-parttype:visible').length;
    jQuery('.js-part-type-count').html(part_type_count);
    
    //Filter checkboxes filtering
    var filterCount = {};
    for(var i in mappings.step3.filters)
    {
        var filterId = parseInt(i) + 1;
        filterCount[filterId] = [];
        for (var j in mappings.step3.filters[i].mappings)
        {
            var mapping = mappings.step3.filters[i].mappings[j];
            for(var k in dataLeft)
            {
                if (dataLeft[k].mappings.indexOf(mapping) >= 0 && filterCount[filterId].indexOf(k) < 0) {
                    filterCount[filterId].push(k);
                }
            }
        }
        if (filterCount[filterId].length > 0 || (lastKey != -1 && mappings.step3.filters[i].category == logicalFilter[lastKey].category)) {
            document.getElementById('filter-container-' + filterId).classList.remove('disabled');
        } else {
            document.getElementById('filter-container-' + filterId).classList.add('disabled');
        }
        var count = filterCount[filterId].length;
        document.getElementById('filter-count-' + filterId).innerHTML = '(' + count + ')';
    }

 $('.js-hide-before-refresh').removeClass('display-none');

}

function expandAll(){
    jQuery('.js-part').removeAttr('style');
    jQuery('.js-parttype-header').each(function(){
        $(this).find('.fa').removeClass('fa-plus-circle').addClass('fa-minus-circle');
        SortPartsBy.sort(this.parentElement);
    });
} 

function union_arrays (x, y) {
    var obj = {};
    for (var i = x.length-1; i >= 0; -- i)
       obj[x[i]] = x[i];
    for (var i = y.length-1; i >= 0; -- i)
       obj[y[i]] = y[i];
    var res = []
    for (var k in obj) {
      if (obj.hasOwnProperty(k))  // <-- optional
        res.push(obj[k]);
    }
    return res;
}


var SortPartsBy = new function(){ // Develop as pluginable
    var _ = this;
    function ASC($a,$b){
        function s(a,b){
            if(isNaN(a) && isNaN(b)) return a < b ? -1 : a > b ? 1 : 0; // Text compare
            a = parseFloat(a);
            b = parseFloat(b);
            if(!a) a = Infinity; // NO Price available always in the end
            if(!b) b = Infinity;
            if($a == b) return 0; // Compare Infinity
            return a-b;
        }
        for(var i in type){
            var r = s($a.dataset[type[i]], $b.dataset[type[i]]);
            if(r!=0) return r;
        }
        return 0;
    }
    function DESC($a,$b){
        function s(a,b){
            if(isNaN(a) && isNaN(b)) return a < b ? 1 : a > b ? -1 : 0; // Text compare
            a = parseFloat(a);
            b = parseFloat(b);
            if(!a) a = -Infinity; // NO Price available always in the end
            if(!b) b = -Infinity;
            if($a == b) return 0; // Compare Infinity
            return b-a; 
        }
        for(var i in type){
            var r = s($a.dataset[type[i]], $b.dataset[type[i]]);
            if(r!=0) return r;
        }
        return 0;
    }

    var type = ["manufacturer","partnumber"] //dataset partnumber, unitprice ...
    var sort = ASC;
    jQuery('document').ready(function() {
        jQuery('.js-container-sort select').on('change',function(){
            type = this.value.split(',');
            sort = $(this).find(':selected').data('order')=='DESC'?DESC:ASC;
            _.sort();
        });
        /*jQuery('.js-container-sort-store select').on('change',function(){
            type = this.value.split(',');
            sort = $(this).find(':selected').data('order')=='DESC'?DESC:ASC;
            _.sort('.js-map-stores-holder');
        });*/
        //jQuery('.js-container-sort select').trigger('change');
    });
    _.sort = function(dom){
        if(dom != undefined){
            var elements = [];
            $(dom).children('.js-part').each(function(){
                elements.push(this);
                //$(this).remove();
            })
            elements.sort(sort);
            elements.forEach(function(el){
                $(dom).append(el);
            });
        }
        else{
            $('.js-parttype').each(function(){
                if($(this).children('.js-parttype-header').children('.fa-plus-circle').length) return;
                _.sort(this);
            })
        }
    }
}

