function JSTranslator(){
    var lang = "en";
    if(document.querySelector("html").lang != undefined)
        lang = document.querySelector("html").lang;
    var trans = {
        en:{
            'No specified' : 'Not specified',
            '$ %s' : '$&nbsp;%s',
            'I want command the part #%s': 'Order part #%s on special order ',
            'no-parts' : `Sorry we couldn't find any results.`
        },
        fr:{
            "Close" : "Fermer",
            "Back" : "Retour",
            "Continue" : "Continuer",
            "Search" : "Rechercher",
            'Year': 'Année',
            'Make': 'Fabricant',
            'Model': 'Modèle',
            'Please fill out this field.' : 'Veuillez remplir ce champ.',
            'Please make your selection.' : 'Sélectionnez une option',
            'Wait a moment.': 'Veuillez patienter',
            '$ %s' : '%s&nbsp;$',
            'No specified' : 'Non déterminé',
            'Discount' : 'Rabais',
            'Kobrex ships this order free of charge.' : 'Kobrex offre la livraison gratuite.',
            'Estimated Tax' : 'Taxes estimées',
            'Provincial Tax' : 'Taxe provinciale',
            'Your request has been sent successfully. A manager will answer you shortly.': 'Votre demande a été envoyée avec succès. Un responsable vous répondra sous peu.',
            '/search/step2/' : '/fr/recherche/etape2/',
            'I want command the part #%s' : 'Commander la pièce #%s en commande spéciale',
            'Send' : 'Envoyer',
            'What is your issue?' : 'Quel est votre problème?',
            'Your comment :' : 'Votre commentaire :',
            'Cancel' : 'Anuler',
            '0 Parts found for: ' : 'Aucune pièce trouver pour: ',
            'Change' : 'Changer',
            'Impersonate' : 'Impersonifier',
            'Select a customer to impersonate' : 'Sélectionnez un client à impersonnifier',
            'Type the first digits of the business name or provide the full customer code or phone number.': 'Inscrivez les premiers caractères du nom de l\'entreprise ou l\'intégralité du code client ou du numéro de téléphone.',
            'no-parts' : `Désolé, nous n'avons pas trouvé de résultats.`
        }
    }
    this.set = function($lang){
        if(typeof $lang != "string") throw new TypeError("First parameter need to be a string");
        return lang = $lang;
    }
    this.getLang = function(){
        return lang;
    }
    this.add = function($lang,$key,$text){
        if(typeof $lang != "string" || typeof $key != "string" || typeof $text != "string") throw new TypeError("All parameter need to be a string");
        if(trans[lang] == undefined)
            trans[$lang] = {};
            trans[$lang][$key] = $text;
    }
    this.trans = function($text,$lang){
        if(typeof $text != "string") throw new TypeError("First parameter need to be a string");
        if(typeof $lang != "string") $lang = lang;
        if(trans[$lang]!=undefined && trans[$lang][$text]!=undefined)
            return trans[$lang][$text];
        return $text;
    }
};
// PHP like
window.Translator = new JSTranslator();
// Wordpress like
window.__ = window.Translator.trans;
if (window.jQuery) jQuery.fn._e = function($text,$lang) { this.html( window.__($text,$lang) ); };
Element.prototype._e = function($text,$lang) {this.innerHTML = window.__($text,$lang);};
// Twig like
window.trans = window.Translator.trans;


// SELECT 2
jQuery(document).ready(function(){
    (function(){if(jQuery&&jQuery.fn&&jQuery.fn.select2&&jQuery.fn.select2.amd)var e=jQuery.fn.select2.amd;return e.define("select2/i18n/fr",[],function(){return{inputTooLong:function(e){var t=e.input.length-e.maximum,n="Supprimez "+t+" caractère";return t!==1&&(n+="s"),n},inputTooShort:function(e){var t=e.minimum-e.input.length,n="Saisissez "+t+" caractère";return t!==1&&(n+="s"),n},loadingMore:function(){return"Chargement de résultats supplémentaires…"},maximumSelected:function(e){var t="Vous pouvez seulement sélectionner "+e.maximum+" élément";return e.maximum!==1&&(t+="s"),t},noResults:function(){return"Aucun résultat trouvé"},searching:function(){return"Recherche en cours…"}}}),{define:e.define,require:e.require}})();
});