
function isElementInViewport(el) {
    if (typeof jQuery === "function" && el instanceof jQuery) {
        el = el[0];
    }

    var rect = el.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) 
    );
}

function checkAnimation() {
    var $elem = $('.image-recycle');
    $elem.addClass('rotate');

    if ($elem.length > 0) {
        if (isElementInViewport($elem)) {

            $elem.addClass('rotate');
        } 
    }
}

$(window).scroll(function(){
    checkAnimation();
});
