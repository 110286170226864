jQuery(document).ready(function(){

    function loginFocus() {
        if (jQuery('#login_username').val() == '') {
           jQuery('#login_username').focus(); 
        }else{
            jQuery('#login_password').focus();
        }
    }
    
    loginFocus();

    function changeSegment(segment){
        var check_active;
        if(segment.hasClass('active')){
            check_active = 'active';
        }
        jQuery('.js-segment li').removeClass('active');
        segment.addClass('active');

        

        if(segment.attr('id') != 'tools-and-accessories') {
            
            //show subtitle segment
            jQuery('.js-subtitle-segment').addClass('hide');
            jQuery('.js-subtitle-segment-'+ segment[0]['id']).removeClass('hide');
        } else {
            var d = new Date();
            setCookie(segment, "", d.getTime() - 3600);
            setCookie(segment.attr('id'), null, !segment.hasClass('js-no-seg')); 
        }
      
        if(jQuery('body').hasClass('home')){
        
            if(segment.attr('id') != 'tools-and-accessories') {
                jQuery('.js-container-modal-group').removeClass('active');  
                changeBanner(segment.attr('id'), null, !segment.hasClass('js-no-seg')); 
            } 

            $segment_id = '.js-container-' + segment.attr('id');
            jQuery($segment_id).addClass('active');
            jQuery($segment_id).find('.js-container-default').addClass('active');
            jQuery('.js-search-modal .js-tab-text[data-type=' + segment.attr('id') + ']').removeAttr('style');
            /*jQuery('.js-search-modal .js-search-by-garage-select option.js-default-value').each(function(){
               jQuery(this).html(jQuery(this).data(segment.attr('id')));
            });*/
          
           
            changeTitle(segment.attr('id'));
            changeTab(jQuery('.js-tab-default'));
     
        }else{
            current_url = window.location.href;
            if ( current_url.indexOf('/fr/') !== -1) {
               window.location.href = "/fr/"; 
            } else {
               window.location.href = "/";
            }
            
           setCookie(segment.attr('id'), null, !segment.hasClass('js-no-seg'));
        }
    }
    function changeBanner(segment, persona, cookie){
       // console.log(segment + ' ' + persona);
        var d = new Date();
       setCookie(segment, "", d.getTime() - 3600);
       setCookie(segment, persona, cookie);
       jQuery('.js-container-partial--promo div').css('opacity', 0); 
       jQuery('.js-container-partial--promo .js-bg-'+segment).fadeTo('slow', 1);
    }
    function changeTitle(segment){
        if(segment == 'light-vehicles'){
            jQuery('.js-title-brand-light-vehicles').removeClass('hide');
            jQuery('.js-title-brand-heavy-trucks').addClass('hide');
        }else{
            jQuery('.js-title-brand-light-vehicles').addClass('hide');
            jQuery('.js-title-brand-heavy-trucks').removeClass('hide');
        }
         
    }
  

     function changeTab(tab, check){
        var id = tab[0]['id'];
        jQuery('.search-bar-container').removeClass('active');
        jQuery('.js-container-partial--search-engine .js-tab-pane').removeClass('active');

        jQuery('div#' + id).addClass('active');
        jQuery('.type-search-input').addClass('active');

        jQuery('.js-container-partial--search-engine .js-tab').removeClass('active');
        tab.addClass('active');
        $tab_pane_id = '.js-container-partial--search-engine #tab_pane_' + tab.attr('id'); 
        jQuery($tab_pane_id).addClass('active');

        footerPosition();

        if(jQuery('#car_part_number').hasClass('active'))
            jQuery('.js-search-part-number-text').focus();

        if(check == 1)
            jQuery('.js-container-default').addClass('active');
     } 
 

    function toggleSearchPanel(isPartNumber){
        jQuery('.container-search-tool--banner').slideToggle('slow');
        
        if (isPartNumber) {
            jQuery('.container-search-tool--banner').find('#car_part_number').click();
        }
    }
    jQuery('.js-segment').on('click','li#heavy-trucks, li#light-vehicles', function() {
            changeSegment(jQuery(this));
    });
    jQuery('.js-segment').on('click','li#recreational-vehicles', function() {
            changeSegment(jQuery(this));
    });
    jQuery('.js-segment').on('click','li#tools-and-accessories', function() {
            //setCookie('tools-and-accessories', null, true);
            changeSegment(jQuery(this));
    });
    jQuery('.js-container-partial--search-engine').on('click','.js-tab', function(){
            changeTab(jQuery(this));
    });
    jQuery('.js-container-partial--search-engine').on('click','.js-segment', function(){
            changeTab(jQuery('.js-tab-default'), 1);
    }); 
 
    jQuery('.js-container-partial--search-engine').on('click','.js-container-icon',function() {
        var label_sibling = jQuery(this).siblings('.js-persona-name');
       
    });
    jQuery('.js-container-partial--search-engine .js-search-part-number, .js-step2 .js-search-part-number').on('submit','',function(e) {
        var form = jQuery(this).closest('.js-search-part-number');
        var text = form.find('.js-search-part-number-text');
        if (text.val() != '') {
            var url = form.attr('action').replace('$1', encodeURIComponent(text.val()) );
            window.location.href = url;
        }
        return false;
    });

    jQuery('.js-container-partial--search-engine').on('change', '.js-search-by-garage-select', function (e) {
        var form = jQuery(this).closest('.js-search-by-garage');
        var value = jQuery(this).val();
        var url = form.attr('action');
       // console.log(url);

        if (value != "") {
            var parts = value.split('|');
            url = url.replace('$1', parts[0]).replace('$2', parts[1]).replace('$3', parts[2]);

            window.location.href = url;
        }
    });
   // changeBanner(jQuery('.js-segment .js-segment-tab.active').attr('id'), jQuery('.js-container-persona.active .js-persona-name').attr('id'), false);

     if(jQuery('.active').hasClass('container-tools-and-accessories')){
               jQuery('.container-search-engine').addClass('full');
     }

      
    jQuery('.js-container-partial--modal-session-expired .js-modal-close').on('click', function(){
        jQuery('.js-container-partial--modal-session-expired').addClass('hide');
        jQuery('main .js-mobile-overlay').css('display', 'none');
    });
    jQuery('.header-logo img, .header-navigation--primary li:first-of-type').on('click', function(){
        var cookie = getCookie("netcom-segment"),
        substring = "tools"; 
        //console.log(cookie);
        if(cookie.indexOf(substring) !== -1)
            Cookies.set('netcom-segment', 'light-vehicles', { expires: 365 }); 
        Cookies.set(' netcom-persona', '', { expires: 365 });  
    });
});



