jQuery( document ).ready(function() {
    jQuery('.js-my-brand').on('click', function() {
        my_brand_name = jQuery(this).text();
        my_brand_logo = jQuery(this).data('logo');
        displayParts(my_brand_name, my_brand_logo);
    });
    jQuery('.js-btn--edit').on('click', function() { 
        jQuery(this).addClass('hide');
        jQuery('.js-linecode-brand-label-empty').addClass('hide');
        jQuery('.js-linecode-subcategory-save').addClass('hide'); 
        jQuery('.js-btn--clear-linecode, .js-btn--cancel, .js-btn--save-linecode, .js-linecode-subcategory, .js-linecode-category, .js-linecode-brand-label-global').removeClass('hide');  
        jQuery('.js-linecode-brand').removeClass('hide');
        jQuery('.js-linecode-part, .js-linecode-brand').prop('disabled', false);
    });
    jQuery('.js-brand-dropdown').on('change', function() {
        my_brand_name = jQuery(this).find("option:selected").text();
        my_brand_logo = jQuery(this).find("option:selected").data('logo'); 
        jQuery('.js-linecode-brand').data('brand', my_brand_name);
        jQuery('.js-brand').text(my_brand_name);
        jQuery('.js-btn--edit').removeClass('hide');
        jQuery('.js-btn--clear-linecode, .js-btn--cancel, .js-btn--save-linecode,  .js-linecode-subcategory, .js-linecode-category').addClass('hide'); 
        displayParts(my_brand_name, my_brand_logo);
    });
    jQuery('.js-settings--linecode').on('click', '.js-btn--save-linecode', function() {
        var preferences = new Array();
        jQuery(this).find('span').addClass('hide');
        jQuery(this).find('.fa-cog').removeClass('hide');
        linecode_brand = jQuery('.js-linecode-brand').val();
        brand = jQuery('.js-linecode-brand').data("brand");
        jQuery('.js-settings--linecode .js-linecode-category').each(function() {
            linecode_category = jQuery(this).val();
            category_name = jQuery(this).parent().parent().find('p').text();
            jQuery('.js-settings--linecode .js-subcategory[data-cat="' + category_name + '"]').each(function() {
                linecode_subcategory = jQuery(this).find('.js-linecode-subcategory').val();
                subcategory_name = jQuery(this).find('.js-toggle-parts-linecode span').text();
                 jQuery('.js-settings--linecode .js-part[data-subcat="' + subcategory_name + '"]').each(function() {
                    var linecode_part = '';
                    var preference_el = new Array();
                    var parttype = jQuery(this).data('part');
                    var linecode_part = jQuery(this).find('.js-linecode-part').val();
                    if(linecode_part == "") {
                        if(linecode_subcategory != "") {
                            linecode_part = linecode_subcategory;
                        } else if(linecode_category != "") {
                            linecode_part = linecode_category;
                        } else if(linecode_brand != "") {
                            linecode_part = linecode_brand;
                        }
                        jQuery(this).find('.js-linecode-part').val(linecode_part);
                    }
                    preference_el.push(brand);  
                    preference_el.push(parttype);
                    preference_el.push(linecode_part);
                    visibility = 0;
                    preference_el.push(visibility); 
                    preferences.push(preference_el);
                });
            });
        });
        jQuery.ajax({
            url: '/ajax/addlinecode',
            dataType: 'JSON',
            type: 'POST',
            data: {
                preferences : preferences
            },
            success: function(data) {
                jQuery(".js-btn--save-linecode").find('span').removeClass('hide');
                jQuery(".js-btn--save-linecode").find('.fa-cog').addClass('hide');
                jQuery(".js-linecode-category, .js-linecode-subcategory").val('');
                jQuery(".js-btn--cancel").trigger("click");
                getLinecodeCatSubCat();
            },
            error: function() {
                jQuery(".js-btn--save-linecode").find('span').removeClass('hide');
                jQuery(".js-btn--save-linecode").find('.fa-cog').addClass('hide');
                getLinecodeCatSubCat();
            },
            done: function() {
                jQuery(".js-btn--save-linecode").find('span').removeClass('hide');
                jQuery(".js-btn--save-linecode").find('.fa-cog').addClass('hide');
                jQuery(".js-linecode-category, .js-linecode-subcategory").val('');
                jQuery(".js-btn--cancel").trigger("click");
                getLinecodeCatSubCat();
            }
        });
    });
    jQuery('.js-settings--linecode').on('click', '.js-btn--clear-linecode', function() {
        jQuery('.js-settings--linecode input').val('');
    });
     jQuery('.js-settings--linecode').on('click', '.js-btn--cancel', function() {
        jQuery('.js-btn--edit').removeClass('hide'); 
        jQuery('.js-btn--clear-linecode, .js-btn--cancel, .js-btn--save-linecode, .js-linecode-subcategory, .js-linecode-category').addClass('hide'); 
        jQuery('.js-linecode-subcategory-save').removeClass('hide'); 
        jQuery('.js-linecode-part, .js-linecode-brand').prop('disabled', true);
    });
     jQuery('.js-settings--linecode').on('click', '.js-toggle-parts-linecode', function() {
        subcat = jQuery(this).find('span').text();
        if( jQuery(this).find('.fa').hasClass('fa-plus-circle')) {
            jQuery(this).parent().parent().find('.fa').removeClass('fa-minus-circle').addClass('fa-plus-circle');
            jQuery(this).parent().parent().find('.js-part').addClass('hide');
            jQuery(this).find('.fa').toggleClass('fa-plus-circle fa-minus-circle');
            jQuery(this).parent().parent().find('.js-part[data-subcat="' + subcat + '"]').toggleClass('hide');
        } else {
            jQuery(this).find('.fa').removeClass('fa-minus-circle').addClass('fa-plus-circle');
            jQuery(this).parent().parent().find('.js-part[data-subcat="' + subcat + '"]').addClass('hide');
        }   
     });
});

function displayParts(my_brand_name, my_brand_logo) {
    var lang = jQuery('html').attr('lang');
    var array_linecode_categories = [];
    var array_linecode_subcategories = [];
    if(!jQuery('.js-link-edit').hasClass('hide')) {
        class_hide = "hide";
        class_show = "";
        disabled = "disabled";
    } else {
        class_hide = "";
        class_show = "hide";
        disabled = "";
    }
    x = 1;
    jQuery(".js-my-parts").empty(); 
    jQuery('.js-logo').attr("src",my_brand_logo);
    jQuery('js-linecode-brand').data('brand', my_brand_name);
    html ='';
    categories = eval('brands_parts_json["' + my_brand_name + '"]'); 
        
    jQuery.each(categories, function(category) { 
        if(category.indexOf('logo') == -1) {
            if (x%4 == 1) {
                html+= '<div class="row p4 px2">';
            }
            html+= '<div class="col-md-3 container pl0 js-category">';
             html+='<div class="category container p0"><p class="col-sm-9 col-xs-9 px0 pb1 text-bold">' + category + 
                    '</p> <span class="col-sm-3 col-xs-3 p0 pr1"><input type="text" class="pull-right js-linecode-category ' + class_hide + '" maxlength="12" size="12" value=""></span></div>';

            jQuery.each(eval('brands_parts_json["' + my_brand_name + '"]["' + category + '"]'), function(subcategory) {
                 html+='<div class="subcategory js-subcategory container col-md-12 col-xs-12  p1" data-cat="' + category + '">' +
                 '<div class="col-sm-9 col-xs-9 px0 hover text-bold js-toggle-parts-linecode">' +
                 '<i class="fa fa-plus-circle  pull-left mb1"></i> <span>' + subcategory + 
                 '</span></div> <div class="col-sm-3 col-xs-3 px0 text-center">' +
                 '<span class="js-linecode-subcategory-save ml4 ' + class_show + '" data-subcat="' + subcategory + '"></span>' +
                 '<input type="text" class="pull-right js-linecode-subcategory ' + class_hide + '" maxlength="12" size="12" value=""></div></div>';
                var array_linecode_parts = [];
                var array_linecode_parts_unique = [];
                jQuery.each(eval('brands_parts_json["' + my_brand_name + '"]["' + category + '"]["' + subcategory + '"]'), function(part, linecode) {
                    html+='<div class="mt1 hide part js-part" data-subcat="' + subcategory + '" data-part="' + part + '"><span class="col-sm-9 col-xs-9 px0 pl2 pt1">' + 
                    part + '</span> <span class="col-sm-3 col-xs-3 px0 pt1 pr1"><input type="text" class="pull-right js-linecode-part" maxlength="12" size="12" value="' +
                    linecode + '" ' + disabled + '></span></div>';
                });
            }); 
            html+='</div>'; 
             if (x%4 == 0 && x != 1) { 
                html+= '</div>';
            }
            x++;
        }
    });
    jQuery(".js-my-parts").append(html);
    getLinecodeCatSubCat();
}
function getLinecodeCatSubCat() {
   jQuery('.js-toggle-parts-linecode').each( function() {
        array_linecode_parts = [];
        array_linecode_parts_unique = [];
        subcategory_name = jQuery(this).find('span').text(); 
        jQuery(this).parent().parent().find('.js-part[data-subcat="' + subcategory_name + '"]').each(function() {
            var linecode = jQuery(this).find('.js-linecode-part').val();
            array_linecode_parts.push(linecode);
        });
        jQuery.each(array_linecode_parts, function(i, el) {
            if(jQuery.inArray(el, array_linecode_parts_unique) === -1) array_linecode_parts_unique.push(el);
        });
        count_array_linecode_parts_unique = array_linecode_parts_unique.length;
        
        if (count_array_linecode_parts_unique == 1) {
            jQuery(this).parent().parent().find('.js-linecode-subcategory-save[data-subcat="' + subcategory_name  + '"]').text(array_linecode_parts_unique[0]);
        } else {
            jQuery(this).parent().parent().find('.js-linecode-subcategory-save[data-subcat="' + subcategory_name  + '"]').text("●●●").css('font-size', '10px'); 
        }
    });
    var array_linecode_subcat_unique = [];
    jQuery('.js-linecode-subcategory-save').each( function() {
        array_linecode_subcat = [];
        var linecode = jQuery(this).text();
        if(linecode != '') {
            array_linecode_subcat.push(linecode);
            jQuery.each(array_linecode_subcat, function(i, el){
                if(jQuery.inArray(el, array_linecode_subcat_unique) === -1) 
                    array_linecode_subcat_unique.push(el);
            });
        }
    });
    count_array_linecode_subcat_unique = array_linecode_subcat_unique.length;
    jQuery('.js-linecode-brand-label-empty, .js-linecode-brand-label-global').addClass('hide');
    if (count_array_linecode_subcat_unique == 1) {
        jQuery('.js-linecode-brand').val(array_linecode_subcat_unique[0]);
        jQuery('.js-linecode-part, .js-linecode-brand').prop('disabled', true);
        jQuery('.js-linecode-brand-label-global, .js-linecode-brand').removeClass('hide');
    } else {
        jQuery('.js-linecode-brand').val('');
        jQuery('.js-linecode-brand-label-empty, .js-linecode-brand').removeClass('hide');
    }
}