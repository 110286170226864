function Grouptype(data){
    var  _ = this;
    var DOMObject = null;
    var grouptypecontainer = null;
    function construct(){
        var html = '<div class="jobs-group__job col-md-2 col-sm-3 col-xs-6  flex flex-column-reverse relative">';
        html += ' <label for="checkbox-grouptype-'+data['partGroupID']+'">';
        html += '<img class="jobs-group__job__img__subgroup" src="'+ data['partGroupImageURL']+'">';
        html += '<span class="jobs-group__job__title flex flex-j-center">'+ data['partGroupName'] +'</span>';
        html += '</label>';
        html += '<input data-job-group-id="'+data['partGroupID']+'" data-job-name="'+ data['partGroupName'] +'" type="checkbox" class="js-chk-job jobs-group__job__checkbox" data-job-serial="-" id="checkbox-grouptype-'+data['partGroupID']+'" data-type="manufacturer" value="">';
        html += '<label class="checkbox-wrapper checkbox-wrapper__popup" for="checkbox-grouptype-'+data['partGroupID']+'"></label>';
        html += '</div>';

        DOMObject = $(html);

        DOMObject.find('input').on('change',function(){
            grouptypecontainer.updateResultBtnStatus();
        });
    }

    _.SetGrouptypeContainer = function(container){
        grouptypecontainer = container;
    }

    _.GetDOMObject = function(){  
        return DOMObject;
    }

    _.Destroy = function(){
        DOMObject = null;
    }

    _.IsChecked = function(){
        return DOMObject.find('input').prop('checked');
    }

    _.Check = function(){
        DOMObject.find('input').prop("checked",true);
        grouptypecontainer.updateResultBtnStatus();
    }

    _.UnCheck = function(){
        DOMObject.find('input').prop("checked",false);
        grouptypecontainer.updateResultBtnStatus();
    }

    _.GetSelection = function(){
        if(_.IsChecked()){
            return data.partGroupItems;
        }
        return false;
    }

    construct();
}