jQuery('document').ready(function(){
    if ($('.js-product-info-tab').length == 1) {
        $('.js-product-info-tabs').hide();
    }
    jQuery('.js-product-info-tab').click(function(){
         jQuery('.js-container-content, .js-product-info-tab').removeClass('active');
         tab_id = jQuery(this).attr('id');
          jQuery('#container-' + tab_id + ', #' + tab_id).addClass('active'); 
    });
    jQuery('.js-link-see-more').click(function(){
        jQuery('.js-more').fadeToggle();
        jQuery(this).find('.js-label-see-more').toggleClass('hide');
    });

    jQuery('.js-step3').on('click', '.js-btn-see-more', function() {
        jQuery('.js-step3').find('.js-toggle-attribute').toggleClass('hide');
        jQuery('.js-step3').find('.js-label-see-more').toggleClass('hide');
    });

   if (jQuery('input[name=anchor]').val() == 'buyers-guide') {
        jQuery('.js-product-info-tab#buyers-guide').addClass('active'); 
        jQuery('.js-container-content.container-buyers-guide').addClass('active');
          jQuery('html, body').animate({
            scrollTop: jQuery("a[name=buyers-guide]").offset().top
            }, 500); 
   } else {
        jQuery('.js-product-info-tab:first-of-type').addClass('active'); 
        jQuery('.js-container-content:first-of-type').addClass('active');
   }
   

     jQuery('.js-button-toggle').click(function(){
        jQuery('.js-applicative-info').slideToggle();
        jQuery(this).find('.fa').toggleClass('fa-plus-circle fa-minus-circle');
    });    

    /*jQuery('.slickslide').slick({
        dots: true,
        infinite: true,
        speed: 500,
        fade: false,
        slide: 'li',
        cssEase: 'linear',
        centerMode: true,
        slidesToShow: 1,
        variableWidth: true,
        autoplay: false,
        autoplaySpeed: 4000,
        asNavFor: '.slider-nav',
        responsive: [{
            breakpoint: 800,
            settings: {
                centerMode: false,
                centerPadding: '40px',
                variableWidth: false,
                slidesToShow: 1,
                dots: true
            },
            breakpoint: 1200,
            settings: {
                
                centerMode: false,
                centerPadding: '40px',
                variableWidth: false,
                slidesToShow: 1,
                dots: true

            }
        }],
        customPaging: function (slider, i) {
            return '<button class="tab">' + jQuery('.slick-thumbs li:nth-child(' + (i + 1) + ')').html() + '</button>';
        }
    });*/

    /*$('.bx-slider').bxSlider({
        useCss: false,
        pagerCustom:".bx-slider-controls .bx-slider-dots",
        shrinkItems:true,
        slideWidth: 625,
        adaptiveHeight:true,
        prevText:"",
        nextText:"",
        prevSelector:".bx-slider-controls .prev",
        nextSelector:".bx-slider-controls .next"
    });*/
    new FexibleSlider('.bx-slider', { 
        pagerCustom:".bx-slider-controls .bx-slider-dots",
        prevSelector:".bx-slider-controls .prev",
        nextSelector:".bx-slider-controls .next"
    });



 /*jQuery('.container-video').slickLightbox({ 

    closeOnBackdropClick : false,
    caption: 'caption',
    slick : function ($e) {

      $e.find('.slick-lightbox-slick-iframe').each(function () { 
         jQuery(this)
          .attr('data-src',  jQuery(this).attr('src'))
          .attr('src', '')
      })

      function clearIframe (slick, index) {
        var $iframe =  jQuery(slick.$slides.get(index)).find('.slick-lightbox-slick-iframe')
        if ($iframe.length) {
          setTimeout(function () {
            $iframe.attr('src', '')
          }, slick.options.speed)
        }
      }

      function loadIframe (slick, index) {

        var $iframe =  jQuery(slick.$slides.get(index)).find('.slick-lightbox-slick-iframe')
        if ($iframe.length) $iframe.attr('src', $iframe.attr('data-src')) 
      }

      /**
       * Return slick instance
       *//*
      return $e.find('.slick-lightbox-slick') 
        .on('init', function (event, slick) {
          loadIframe(slick, slick.currentSlide)
        })
        .on('beforeChange', function (event, slick, currentSlide, nextSlide) {
          clearIframe(slick, currentSlide)
          loadIframe(slick, nextSlide)
        })
        .slick()
    }
 });*/

    /**
     * Add to cart action
     */
    jQuery('.js-product-detail').on('click', '.js-btn-addtocart', function(){
        var url = document.getElementById('addtocart').value;
        var year = document.getElementById('year').value;
        var make = document.getElementById('make').value;
        var model = document.getElementById('model').value;
        var part = jQuery(this).closest('.js-product-detail');
        var quantityControl = part.find('.js-txt-quantity');
        var manufacturer = part.data('manufacturer');
        var description = part.data('description');
        var parttypename = part.data('parttypename');
        var productCode = part.data('partnumber');
        var lineCode = part.data('linecode');
        var buttonClicked = jQuery(this);
        var storeId = jQuery(this).data('store-id');
        var sid = jQuery('#sid').val();
        var unitPrice = jQuery('#unitprice').val();
        var deposit = jQuery('#deposit').val();
        var tax = jQuery('#tax').val();
        
        quantityControl.removeClass('error');
        if (quantityControl.val() == 0) {
            quantityControl.addClass('error');
            return;
        }
        
        var successMsg = part.find('.js-addtocard-msg.js-msg-success');
        var errorMsg = part.find('.js-addtocard-msg.js-msg-success');
        var loading = buttonClicked.find('.js-loading');
        var text = buttonClicked.find('.js-text');
        
        successMsg.css('display', 'none');
        errorMsg.css('display', 'none');
        loading.removeAttr('style');
        text.css('display', 'none');
                    
        $.ajax({
            url:url,
            dataType: 'JSON',
            type: 'POST',
            data: {
                quantity : quantityControl.val(),
                productcode : productCode,
                linecode : lineCode,
                year : year,
                make : make,
                model : model,
                manufacturer : manufacturer,
                description : description,
                parttype : parttypename,
                storeid : storeId,
                sid : sid,
                unitprice : unitPrice,
                deposit : deposit,
                tax : tax
            },
            success: function(data){
                if (data.error) {
                    successMsg.css('display', 'none');
                    errorMsg.removeAttr('style');
                    
                }  else if (data.count > 0) {
                    buttonClicked.css('background', '#313236');
                    successMsg.removeAttr('style');
                    updateCartNumbers();
                    loading.css('display', 'none');
                    text.removeAttr('style');
                    
                }
            }
        });
    });

    function updateCartNumbers() {
        var count = 0;
        $.ajax({
            url:'/ajax/getCartItemCount',
            dataType: 'JSON',
            type: 'GET',
            success: function(data){
                count = data;
                //Total count in the menu
                var menuCount = jQuery('.js-menu-cart-count');
                if (count > 0){
                    if(menuCount.hasClass('hide')) {
                        menuCount.removeClass('hide')
                    }
                    if(count > 9){
                        menuCount.html("9+");
                    }
                    else{
                        menuCount.html(count);
                    }
                } else {
                    menuCount.html('');
                    menuCount.addClass('hide');
                }
            }
        });
    }
    
});