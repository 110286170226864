jQuery( document ).ready(function() {
    jQuery('.js-container__anchors a, .js-btn__scroll, .js-logo a').click(function(){
        var href = jQuery.attr(this, 'href');
        navigateSlide(href)
    });
    jQuery('.js-anchor-down').click(function(){
        var href = jQuery('.circle.active').parent().parent().next().find('a').attr('href');
        if (href != jQuery('.circle:last-of-type').find('a').attr('href'))
            navigateSlide(href)
    });
     jQuery('.js-anchor-up').click(function(){
        var href = jQuery('.circle.active').parent().parent().prev().find('a').attr('href'); 
        if(href != undefined)
            navigateSlide(href)
    });

     url = window.location.href;
     if (url.indexOf('#anchor-') !== -1) {
        needle_index = url.indexOf('#');
        anchor = url.substring(needle_index);
        navigateSlide(anchor);
     }
       
});  
