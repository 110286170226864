function FexibleSlider(selector,attr){
    if(!(this instanceof FexibleSlider))
    	throw new Error("Uncaught TypeError: FexibleSlider is a construtor.");
    var _ = this;
    var current = 0;
    var length = 0;
    var isAnimate=null;
    this.nextSlide = function(){
        if(selector == null) return false;
        _.setSlide(current+1);
    }
    this.prevSlide = function(){
        if(selector == null) return false;
        _.setSlide(current-1);
    }
    this.setSlide = function(index,hard){
        if(selector == null) return false;
        current = index;
        if(current<0)
            current = length+(current%length);
        else if(current>length-1){
            current = current%length
        }
        var left = selector.offsetLeft = selector.children[current].offsetLeft;
        if(hard == undefined || hard == false){
            selector.style["-webkit-transition"] = "left 500ms ease";
            selector.style["-ms-transition"] = "left 500ms ease";
            selector.style["-o-transition"] = "left 500ms ease";
            selector.style["transition"] = "left 500ms ease";
            if(isAnimate!=null) clearTimeout(isAnimate);
            isAnimate = setTimeout(function(){
                selector.style["-webkit-transition"] = "";
                selector.style["-ms-transition"] = "";
                selector.style["-o-transition"] = "";
                selector.style["transition"] = "";
            }, 500);
        }
        if(pager) for(var i=0; i<length; i++){
            if(i == current){
                pager.children[i].classList.add('active')
            }
            else{
                pager.children[i].classList.remove('active')
            }
        }

        selector.style.left = -left+"px";
    };

    selector = document.querySelector(selector);
    if(selector == null) return false;
    length = selector.children.length;
    function wrap(el, wrapper) {
        if(wrapper == undefined) wrapper = document.createElement('div');
        el.parentNode.insertBefore(wrapper, el);
        wrapper.appendChild(el);
        return wrapper;
    }

    var viewport = wrap(selector);
    var wrapper = wrap(viewport);

    selector.classList.add("fx-slider-list");
    viewport.setAttribute("class", "fx-slider-viewport");
    wrapper.setAttribute("class", "fx-slider-wrapper");
    
    var pager = null;
    if(attr != undefined && attr.pagerCustom!= undefined){
        pager = document.querySelector(attr.pagerCustom);
    }

    var prev = null;
    if(attr != undefined && attr.prevSelector!= undefined){
        prev = document.querySelector(attr.prevSelector);
    }
    var next = null;
    if(attr != undefined && attr.nextSelector!= undefined){
        next = document.querySelector(attr.nextSelector);
    }
    if(pager!= null && pager.children.length>1){
        for (var k in pager.children) {
            if(!isNaN(k)){
                if(pager.children[k].dataset["slideIndex"] == undefined)
                    pager.children[k].dataset["slideIndex"] = k+"";
                pager.children[k].addEventListener('click',function(e){
                    e.preventDefault();
                    _.setSlide(parseInt(this.dataset["slideIndex"]));
                });
            }
        }
        
        if(prev!= null)
            prev.addEventListener("click",function(e){
                e.preventDefault();
                _.prevSlide();
            });
        if(next!= null)
            next.addEventListener("click",function(e){
                e.preventDefault();
                _.nextSlide();
            });
    }
    window.addEventListener("resize",function(){
        _.setSlide(current,true);
    });
    _.setSlide(current,true);
    
    return this;
}