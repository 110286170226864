var validationTimeout;
var goButton;
var partTextfield;
jQuery('document').ready(function(){
    function validatePartNumber(e){
        var val = "";
        if(e.type == 'paste')   val = e.originalEvent.clipboardData.getData('text');
        else                    val = jQuery(this).val();
        val = val.trim();
        if(e.type == 'change') jQuery(this).val(val);

        goButton = jQuery(this).closest('.js-search-part-number').find('.js-go-button');
        if(val.length > 2) {
            goButton.removeAttr('disabled');
            goButton.addClass('valid');
        }
        else{
            goButton.attr('disabled','');
            goButton.removeClass('valid');
        }
    }
    jQuery('.js-search-part-number').on('keyup', 'input.js-search-part-number-text', validatePartNumber);
    jQuery('.js-search-part-number').on('change', 'input.js-search-part-number-text', validatePartNumber);
    jQuery('.js-search-part-number').bind('paste', 'input.js-search-part-number-text', validatePartNumber);

    jQuery('.js-search-part-number').on('change', '.js-search-part-number-text.js-select2', function(){
        $(this).closest('.js-search-part-number').trigger('submit');
        $('.js-loading-overlay').removeClass('hide');
    });
    jQuery('.js-search-part-number').on('click', '.js-go-button', function(e) {
        e.preventDefault();
        var form = $(this).closest('.js-search-part-number')
        var input = form.find('.js-search-part-number-text');
        partTextfield = input.val().trim();
        url = input.data('url');
        $('.js-loading-overlay').removeClass('hide');
        $.ajax({
            url:url,
            dataType: 'JSON',
            type: 'POST',
            data: {
                partNumber : partTextfield
            },
            success: function(data){
                if(data.valid == 1) {
                    form.submit();
                } else {
                    input.val('');
                    input.blur();
                    if(lang == 'en') {
                        input.attr("placeholder", "No result found.");
                    } else {
                        input.attr("placeholder", "Aucun résultat trouvé.");
                    }
                    $('.js-loading-overlay').addClass('hide');
                }
                
            },
            error: function(){
                $('.js-loading-overlay').addClass('hide');
                input.val('');
                input.blur();
                if(lang == 'en') {
                    input.attr("placeholder", "No result found.");
                } else {
                    input.attr("placeholder", "Aucun résultat trouvé.");
                }

            }
        });
    });
});
