jQuery(document).ready(function(){
    jQuery(document).on("click",".burger",function(e){
        e.preventDefault();
        document.getElementById('hamburger').style.opacity = 1;
        document.getElementById('hamburger').style.transform = "unset";
    });
    jQuery(document).on("click",".exit-hamburger",function(){
        document.getElementById('hamburger').style.opacity = 1;
        document.getElementById('hamburger').style.transform = "translatex(100%)";
    })
});