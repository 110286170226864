jQuery(document).ready(function() {
    jQuery('.js-step3').on('change', '.js-diagram-check', function() {
        jQuery('.js-container-filter-diagrams, .js-container-categories, .js-container-attributes').toggleClass('hide');
        if (!jQuery('.js-container-filter-diagrams').hasClass('hide')) {
            jQuery('.js-filter-diagrams').find('input:first').attr('checked', 'checked'); 
            diagram_id = jQuery('.js-filter-diagrams').find('input:checked').val();
            jQuery('input#diagram-' + diagram_id).trigger('change');
        } else {
            jQuery('.js-parttype-header-diagram').remove();
            jQuery('.js-parttype-header').removeClass('hide'); 
            jQuery('.js-diagram img, .js-diagram .js-btn-expand').addClass('hide');
            jQuery('.js-part').each(function() {
                part_parttypename = jQuery(this).attr('data-parttypename'); 
                jQuery('.js-parttype-header[data-title="' + part_parttypename + '"]').after(jQuery(this).css('display', 'none'));
            });
        }
    });
});