function GrouptypeContainer(element, year, make, model) {
    var _ = this;
    var id = null;
    var grouptypeslist = [];
    var url = "";
    function constructYMM() {
        _.Show = function () {
            var partGroups = $(element).data("childsPartGroups");
            if (partGroups.length == 1)
                _.RedirectToStep3(_.MapToSelection(partGroups[0].partGroupItems));
            else {
                $(element).find('.container-search-tool').hide();
                _.appendPartGroupsToDOM(partGroups);
                //afficher la section
                $(element).find('.js-grouptype-search-modal').addClass('active');
                $('.js-container-search-tool--overlay').addClass('active');
            }
        }
    }
    function constructBase() {
        id = $(element).data('id');
        url = $(element).find('[data-url]').data('url');
        $(element).find('.js-select-all').on('click', function (e) {
            e.preventDefault();
            _.selectAll();

        })
        $(element).find('.js-unselect-all').on('click', function (e) {
            e.preventDefault();
            _.unSelectAll();
        })

        $(element).find('.js-getresults').on('click', function (e) {
            e.preventDefault();
            _.getResults();
        })
    }
    function construct() {
        $(element).find('.js-grouptype-vehiculedefinition').each(function(){
            var frontPage = jQuery(this).parents('.js-front-page');
            var $this = jQuery(this);
            jQuery(this).VehiculeDefinition({
                vehiculetype : jQuery(this).data('type'),
                year : {
                    selector : '.js-year',
                    type : jQuery(this).find('.js-make').data('url')?'ajax':'none',
                    value : jQuery(this).find('.js-year').data('url')
                },
                make : {
                    selector : '.js-make',
                    type : !jQuery(this).find('.js-make').is(':disabled')?'ajax':'none',
                    value : jQuery(this).find('.js-make').data('url')
                },
                model : {
                    selector : '.js-model-search',
                    type : 'ajax',
                    value : jQuery(this).find('.js-model-search').data('url')
                },
                complete : function(data){
                    year = data.year;
                    make = data.make;
                    model = data.model;
                    _.loadApplicativeData(data.year,data.make,data.model,id);
                    $('.sub-grouptype-container__no-parts').empty();
                }
            })
        });
    }

    _.Show = function() {
        //afficher la section
        $(element).find('.js-grouptype-search-modal').addClass('active');
        $(element).find('.js-container-grouptype--overlay').addClass('active');
    }

    _.Hide = function(){
        $('[data-key-name-button]').removeClass('active');
        $('.container-search-tool--modal').removeClass('active');
        $('.js-container-search-tool--overlay').removeClass('active');
		$('.js-container-grouptype--overlay').removeClass('active');
        $('.solutions__solution').removeClass('active');
        $('.jobs-group').removeClass('active');
        $('.sub-grouptype-container__no-parts').empty();
    }

    _.Destroy = function(){
        grouptypeslist =[];
    }

    $('.js-close-btn').click(function(){
        _.Hide();
        _.Destroy();
        _.ShowVehicleTitle();
         _.ResetYmm();
    });

    _.EnableResultsBtn = function(){
        $(element).find('.js-getresults').prop('disabled',false);
        $(element).find('.js-getresults').removeClass('disabled');
    }

    _.DisableResultsBtn = function(){
        $(element).find('.js-getresults').prop('disabled',true);
        $(element).find('.js-getresults').addClass('disabled');
    }

    _.ResetYmm = function(){
        $(element).find('.js-year').prop('selectedIndex',0).change();
        $(element).find('.js-make').prop('selectedIndex',0).change();
        $(element).find('.js-model-search').prop('selectedIndex',0).change();
    }

    _.GetID = function(){
        return id;
    }

    _.selectAll = function(){
        grouptypeslist.forEach(function(grouptype){
            grouptype.Check();
        });
        _.updateResultBtnStatus();

    }

    _.unSelectAll = function(){
        grouptypeslist.forEach(function(grouptype){
            grouptype.UnCheck();
        });
        _.updateResultBtnStatus();
    }

    _.updateResultBtnStatus = function(){
        checked = false;
        grouptypeslist.forEach(function(grouptype){
            if(grouptype.IsChecked()){
                checked = true;
            }
        });
        if(checked == true){
            _.EnableResultsBtn();
        }
        else{
            _.DisableResultsBtn();
        }
    }

    _.RedirectToStep3 = function(data){
        url = url.replace('$1', encodeURI(year)).replace('$2', encodeURI(make)).replace('$3', encodeURI(model));
        jQuery('.js-loading-overlay').removeClass('hide');
        var form = jQuery('<form action="'+ url +'" method="POST"></form>');

        form.append(jQuery('<input />', {
            type : 'hidden',
            name : 'selection',
            value : JSON.stringify(data)
        }));
        form.append(jQuery('<input />', {
            type : 'hidden',
            name : 'token',
            value : jQuery('.js-step2 #token').val()
        }));
        jQuery('body').append(form);
        jQuery('#js-spinner').addClass('active');
        form.submit();

    }

    _.getResults = function(){
        var data = [];
        grouptypeslist.forEach(function(grouptype){
            if(grouptype.IsChecked()){
                data = data.concat(_.MapToSelection(grouptype.GetSelection()));
            }
        });
        _.RedirectToStep3(data);
    }

    _. MapToSelection = function(items){
        var selections = [];
        items.forEach(function(item){
            var selection = {};
            selection.Manufacturer = item.manufacturerName;
            selection.PartTerminology = item.partTypeName;
            selection.SubCategory = item.subCategoryName;
            selection.Category = item.categoryName;
            selection.isNA = !item.isApplicative;
            selections.push(selection);
        });
       
        return selections;
    }

    _.loadApplicativeData = function (year, make, model) {
        grouptypeslist = [];
        var url = "/ajax/getGrouptypeBy/";
        $.ajax({
            url:url,
            dataType: 'JSON',
            type: 'POST',
            data: {
                year : year,
                make : make,
                model : model,
                containerID : _.GetID()
            },
            success: function(data) {
                if (data['grouptypes'].length == 1){
                    _.RedirectToStep3(_.MapToSelection(data['grouptypes'][0].partGroupItems));
                }
                else if(data['grouptypes'].length == 0){
                    _.ShowNoGroupType();
                }
                else{
                    _.appendPartGroupsToDOM(data['grouptypes']);
                    _.HideVehicleTitle();
                }
            }
        });
    };

    _.ShowNoGroupType = function(){
        $('.sub-grouptype-container').removeClass('display-none');
        var html = '<div class="sub-grouptype-container__no-parts">'+
                    '<p class="text-center full white pt2 pb2">'+__('no-parts')+'</p>'+
                   '</div>';
        $('.sub-grouptype-container').append(html);
        path = (Translator.getLang()!='en'?'/'+Translator.getLang():'')+'/no_result'
		window.history.pushState({href: path}, '', path);
    }

    _.HideVehicleTitle = function(){
        $('.ymm-popup-title--vehicle').addClass('hide');
        $('.ymm-popup-title--parttype').removeClass('hide');
    }

    _.ShowVehicleTitle = function(){
        $('.ymm-popup-title--vehicle').removeClass('hide');
        $('.ymm-popup-title--parttype').addClass('hide');
    }

    _.appendPartGroupsToDOM = function (partGroups) {
        $(element).find('.jobs-wrapper').empty();
        $(element).find('.jobs-group').addClass('active');
        partGroups.forEach(function (partGroup) {
            var subGrouptype = new Grouptype(partGroup);
            subGrouptype.SetGrouptypeContainer(_);
            grouptypeslist.push(subGrouptype);
            $(element).find('.jobs-wrapper').append(subGrouptype.GetDOMObject());
        })
    }

    if (year != null && make != null && model != null)
        constructYMM();
    else
        construct();
    constructBase();
}