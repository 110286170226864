jQuery( document ).ready(function() {
    jQuery('.js-paginator').on('click', '.js-letter-page', function(){
        if (jQuery(this).hasClass('selected')){
            return false;
        }
        var brands = jQuery('.js-settings--catalog, .js-step2');
        var value = jQuery(this).data('value');
        
        
        if (value == 'all')
        {
            brands.find('.js-manufacturer').removeClass('hide');
            brands.find('.js-letter-separator').removeClass('hide');
        }
        else
        {
            brands.find('.js-manufacturer').addClass('hide');
            brands.find('.js-letter-separator').addClass('hide');
            brands.find('.js-manufacturer[data-category=' + value + '], .js-letter-separator[data-category=' + value + ']').removeClass('hide');
        }
        
        var active = 0;
        brands.find('.js-manufacturer').each(function(){
            if (!jQuery(this).hasClass('hide'))
            {
                active++;
            }
        });
        
        if (active === 0)
        {
            brands.find('.js-no-brands').removeClass('hide');
        }
        else
        {
            brands.find('.js-no-brands').addClass('hide');
        }
        
        brands.find('.js-letter-page').removeClass('selected');
        jQuery(this).addClass('selected');
    });
});
