var lang = document.documentElement.lang;


function initMap() {
  var mapDiv = document.getElementById('map');
  myLatLng = new google.maps.LatLng(45.581228, -73.455093);
  myLatLng2 = new google.maps.LatLng(45.581228, -73.555093);
  var map = new google.maps.Map(mapDiv, {
      center: myLatLng2,
      zoom: 13
  });
  var marker = new google.maps.Marker({
    position: myLatLng,
    map: map,
    title: ''
  });


map.setOptions({styles: styles});
}


function initMapStore(lat,lng) {
  var mapDiv = document.getElementById('map-store');
  myLatLng = new google.maps.LatLng(lat, lng);

  var map = new google.maps.Map(mapDiv, {
      center: myLatLng,
      zoom: 13
  });
  var marker = new google.maps.Marker({
  position: myLatLng,
  map: map,
  title: ''
});


map.setOptions({styles: styles});

}





function initMapStoreLocator() {
  myLatLng2 = new google.maps.LatLng(45.501228, -73.555093);
  map = new google.maps.Map(document.getElementById('map-store-locator'), {
    center: myLatLng2,
    zoom: 12,
    gestureHandling: 'greedy', 
    zoomControl: true,
    zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_CENTER
    },
    streetViewControl: true,
    streetViewControlOptions: {
        position: google.maps.ControlPosition.RIGHT_CENTER
    },
  });
  map.setOptions({styles: styles});
  setMarkers(map);


  // Create the search box and link it to the UI element.
  var input = document.getElementById('pac-input');
  var searchBox = new google.maps.places.SearchBox(input);
 //map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);


  // Bias the SearchBox results towards current map's viewport.
  map.addListener('bounds_changed', function() {
    searchBox.setBounds(map.getBounds());
  }); 

  searchBox.addListener('places_changed', function() {
    var places = searchBox.getPlaces();

    if (places.length == 0) {
      return;
    }

 
    var bounds = new google.maps.LatLngBounds();
    places.forEach(function(place) {
      if (!place.geometry) {
        console.log("Returned place contains no geometry");
        return;
      }

      if (place.geometry.viewport) {
        // Only geocodes have viewport.
        bounds.union(place.geometry.viewport);
        nearMarkers(place, marker_array_yellow);
        nearMarkers(place, marker_array_gray);
        bounds.extend(markers_near.position);
      } else {
        bounds.extend(place.geometry.location);
      }
    });
    map.fitBounds(bounds);
  });


  // geolocation
  if (navigator.geolocation) {
   
    navigator.geolocation.getCurrentPosition(function(position) {
      
      var pos = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      };

      map.setCenter(pos);
   
    });
  } else {
    // Browser doesn't support Geolocation
  }

  
}

if (color_primary != "") {
  styler =  color_primary;
} else {
  styler =  ""; 
}


var styles = [

   {
        "stylers": [
            {
               "hue" : styler
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [
            {
                "lightness": 100
            },
            {
                "visibility": "simplified"
            }
        ]
    }
];

function rad(x) {return x*Math.PI/180;}
function nearMarkers( place, marker_array ) {
 
    var lat = place.geometry.location.lat();
    var lng = place.geometry.location.lng();
    var R = 6371; // radius of earth in km
    var distances = [];
    var closest = -1;
    for( i=0;i<marker_array.length; i++ ) {
        var mlat = marker_array[i].position.lat();
        var mlng = marker_array[i].position.lng();
        var dLat  = rad(mlat - lat);
        var dLong = rad(mlng - lng);
        var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.cos(rad(lat)) * Math.cos(rad(lat)) * Math.sin(dLong/2) * Math.sin(dLong/2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        var d = R * c;
        distances[i] = d;
        if ( closest == -1 || d < distances[closest] ) {
            closest = i;
        }
    }
    markers_near = marker_array[closest];

    return markers_near;
}

var marker_array_yellow = new Array();
var marker_array_gray = new Array();

function getPosition(string, subString, index) {
   return string.split(subString, index).join(subString).length;
}

function setMarkers(map) {

  // Marker sizes are expressed as a Size of X,Y where the origin of the image
  // (0,0) is located in the top left of the image.

  // Origins, anchor positions and coordinates of the marker increase in the X
  // direction to the right and in the Y direction down.
  var image_yellow = {
    url: '/dist/images/map-marker-yellow.png',
    size: new google.maps.Size(60, 60),
    //origin: new google.maps.Point(0, 32),
    //anchor: new google.maps.Point(0, 32) 
  };
  var image_gray = {
    url: '/dist/images/map-marker-gray.png',
    size: new google.maps.Size(60, 60),
    //origin: new google.maps.Point(0, 32),
    //anchor: new google.maps.Point(0, 32) 
  };
  infowindow  = new InfoBubble({
      map: map,
      content: '',
      position: new google.maps.LatLng(-32.0, 179.0),
      shadowStyle: 3,
      padding: 20,
      backgroundColor: 'rgb(255,255,255)',
      borderRadius: 0,
      arrowSize: 10,
      borderWidth: 0,
      minWidth: 200,  
      minHeight: 100,
      borderColor: '#2c2c2c',
      disableAutoPan: true,
      hideCloseButton: true,
      arrowPosition: 30,
      backgroundClassName: 'transparent', 
      arrowStyle: 0
  });
  if (lang =='fr') {
    url = window.location.href;
    url = url.replace('fr/', '');
  } else {
   url = window.location.href;
  }
  index_start = getPosition(url, '/', 4); 
  index_end = getPosition(url, '/', 5); 
  manufacturer = url.substring(index_start+1, index_end);

  index_start = getPosition(url, '/', 5); 
  index_end = getPosition(url, '/', 6); 
  part_type = url.substring(index_start+1, index_end);

  index_start = getPosition(url, '/', 6); 
  index_end = getPosition(url, '/', 7); 
  part_number = url.substring(index_start+1, index_end);

  jQuery.ajax({
      url: '/wp-content/themes/netcom/inc/store-ids.php', 
      type: 'POST', 
      data: {
        manufacturer : manufacturer,
        part_number : part_number
      }, 
      success: function(data) {
        var store_ids = data;
        parseMarkers(store_ids);
      }

  });


function parseMarkers(store_ids) {
  for (var i = 0; i < stores.length; i++) {
      var store = stores[i];
      if (store_ids.includes( store.id ) == true) {
          icon = image_yellow;
        } else {
          icon = image_gray;
        }
      var marker = new google.maps.Marker({
        position: {lat: Number(store.lat), lng: Number(store.long)},
        map: map,
        icon: icon,
        title: store.name,
        id: store.id
      });

      

      (function (marker) {
        google.maps.event.addListener(marker, "click", function (e) {
     
          store_id = marker.id;
          
          jQuery.ajax({
              url: '/wp-content/themes/netcom/inc/pos.php', 
              type: 'POST', 
              data: {
                manufacturer : manufacturer,
                part_number : part_number, 
                part_type : part_type, 
                store_id : store_id 
              }, 
              success: function(data) {
              
                if (data != '') {
                   part_info = JSON.parse(data);
                   var part_info_text = "<div class='col-md-12 col-xs-12 p0'>";
                   part_info_text += "<h3 class='color--primary'>" + part_number + " | " + decodeURI(manufacturer.split('+').join(' ')) + " " + decodeURI(label.split('+').join(' ')) + " " +  decodeURI(part_type.split('+').join(' ')) +   "</h3></div>";
                   if (image_part) {
                      part_info_text += "<div class='col-md-6 col-xs-6 p0 text-center'><img src='"+ image_part +"'>"; 
                   } else {
                      part_info_text += "<div class='col-md-6 col-xs-6 p0 text-center'><img src='/dist/images/no-image.png'>";
                   }
                   
                   part_info_text += "</div>";
                   part_info_text += "<div class='text-right  col-md-6 col-xs-6 p0'>";
                   part_info_text += "<div class='unit-price color--primary text-bold'>";

                   if (part_info.price_discount > 0) {
                     
                     if (lang =='fr') {
                        part_info_text += part_info.price_discount.toFixed(2) + "$";
                        part_info_text += "</div>";
                        part_info_text += "<br>Prix régulier: " + part_info.price.toFixed(2) + "$";
                        part_info_text += "<br><div class='color--primary'>";
                        part_info_text += "Vous sauvez: $" + Number(part_info.price.toFixed(2) - part_info.price_discount.toFixed(2)).toFixed(2) + "$";
                     } else {
                        part_info_text += "$" + part_info.price_discount.toFixed(2);
                        part_info_text += "</div>";
                        part_info_text += "<br>Regular price: $" + part_info.price.toFixed(2);
                        part_info_text += "<br><div class='color--primary'>";
                        part_info_text += "You save: $" + Number(part_info.price.toFixed(2) - part_info.price_discount.toFixed(2)).toFixed(2); 

                     }
                     
                   } else {

                      if (lang =='fr') {
                        part_info_text += part_info.price.toFixed(2) + "$";
                     } else {
                        part_info_text += "$" + part_info.price.toFixed(2);
                     }

                   }

                   part_info_text += "</div>";
                   
                  
                  if (part_info.tax > 0) {
                       part_info_text += '<div class="tax"><span class="fa fa-leaf"></span> ';
                      if (lang =='fr') {
                        part_info_text += "Frais env.: +" + part_info.tax.toFixed(2) + "$"; 
                     } else {
                        part_info_text += "Env. tax: + $" + part_info.tax.toFixed(2)+ "</div>";
                     }
                  }
                  
                   if (part_info.deposit > 0) {
                    part_info_text += '<div class="deposit"><span class="fa fa-recycle"></span> ';
                      if (lang =='fr') {
                        part_info_text += part_info.deposit.toFixed(2) + "$<br>";
                     } else {
                        part_info_text += "$" + part_info.deposit.toFixed(2) + "</div>";
                     }
                   }
                
                  //start add to cart
                  part_info_text += '<div class="quantity-control">'; 
                     if (lang =='fr') {
                        part_info_text += 'Qty';
                     } else {
                        part_info_text += 'Qté';
                     }
                  part_info_text += '<input type="number" class="qty-num js-txt-quantity" size="3" min="1" value="1">';
                  part_info_text += '</div>';
                  part_info_text += '<button class="js-btn-addtocart btn addtocart-button" data-store-id="' + store_id + '" data-unitprice="' + part_info.price.toFixed(2) + '" data-tax="' + part_info.tax.toFixed(2) + '" data-deposit="' + part_info.deposit.toFixed(2) + '">';
                    if (page_type == 1 || page_type == 2 || page_type == 3) {
                        part_info_text += '<i class="fa fa-shopping-cart"></i>';
                    } else {
                        part_info_text += '<i class="fa fa-star"></i>';
                    }
                  part_info_text += '</button>';
                  part_info_text += '<div class="js-addtocard-msg js-msg-success addtocard-msg" style="display:none">';
                  if (lang =='fr') {
                        if (page_type == 1 || page_type == 2 || page_type == 3) {
                          part_info_text += 'Item ajouté au panier';
                        } else {
                          part_info_text += 'Item ajouté à vos favoris';
                        }
                     } else {
                         if (page_type == 1 || page_type == 2 || page_type == 3) {
                          part_info_text += 'Item added to cart';
                        } else {
                          part_info_text += 'Item added to wishlist';
                        }
                     }
                  part_info_text += '</div>';
                  //end add to cart


                  part_info_text += "<div><img src='"+ logo_url +"' class='fifty mt2'></div>";
                  part_info_text += '</div>';                
                  jQuery('.js-modal--content .js-part-info').html(part_info_text);
                }
              }
          });
        });
    })(marker);

    if(marker.icon.url.indexOf('yellow') !== -1) {
      marker_array_yellow.push(marker);
    } else {
      marker_array_gray.push(marker);
    }
      

      

    var content = '<div class="infobubble-text js-infobubble-text">' + store.name + '</div>';
    var content2 =  '<div class="js-part-info part-info container p0"></div>' +
    '<h3 class="color--gray-base">' + store.name + '</h3>' + 
    '<ul class="ml0"><li><i class="fa-absolute fa fa-phone" aria-hidden="true"></i><span class="tel">' + store.phone + '</span><br><br>' + 
    '<li><i class="fa-absolute fa fa-map-marker" aria-hidden="true"></i><p>' + store.address + '<br>' + 
    store.city +  ', ' + 
    store.province + ' ' +
    store.postal_code + '<br>' +
    store.country + '</p></li></ul><br><br>';

    if (lang =='fr') {
      button_label_direction = 'Itinéraire';
      button_label_visit = 'Visitez ce magasin à '; 
      link = '/fr/magasin/';
    } else {
      button_label_direction = 'Directions';
      button_label_visit = 'Visit this store in ';
      link = '/store/';
    }

    //content2 +='<button class="btn" onclick="window.open(\'http://maps.google.com/maps?daddr=' + store.lat + ',' + store.long +'&amp;saddr=\')">' + button_label_direction + '</button>';
    content2 +='<button class="btn" onclick="window.location.href=\'' + link + store.city + '/' + store.name.replace(' ','-').replace(/'/g, "\\'")  + '/' + store.id + '\'">' + button_label_visit + store.city + '</button>'
    google.maps.event.addListener(marker,'click', (function(marker,content,content2,infowindow){ 
      return function() {
         infowindow.setContent(content);
         jQuery('.js-infobubble-text').css('background-color','red');
         jQuery('.js-info-bubble-close').attr('src','/dist/images/close.png');
         jQuery('.js-modal--store-info').css('display', 'none');
         
         if (content) {
            jQuery('.js-modal--content').html(content2);
            jQuery('.js-modal--store-info').fadeIn()
         }
         
         map.panTo(marker.getPosition());
         infowindow.close();
         infowindow.open(map,marker);
        };
      })(marker,content,content2,infowindow));
    }

 

   
    var clusterStylesYellow = [
     {
        textColor: 'black',
        url: '/dist/images/map-marker-yellow.png', 
        height: 72,
        width: 60,
        textSize: 20,
        anchor:[12,-2]

      }
    ];
   
    var marker_style_yellow  = {
      gridSize: 50,
      styles: clusterStylesYellow,
      maxZoom: 15
    };

    var clusterStylesGray = [
     {
        textColor: 'black',
        url: '/dist/images/map-marker-gray.png',
        height: 72,
        width: 60,
        textSize: 20,
        anchor:[12,-2]

      }
    ];
   
    var marker_style_gray  = {
      gridSize: 50,
      styles: clusterStylesGray,
      maxZoom: 15
    };
 
    var markerClusterYellow = new MarkerClusterer(map, marker_array_yellow, marker_style_yellow);
    var markerClusterGray = new MarkerClusterer(map, marker_array_gray, marker_style_gray);

    var url = new URL(url_string);
    var pos_id = url.searchParams.get("pos_id");
    
    if(pos_id) {
      for (var i = 0; i < marker_array_yellow.length; i++) {
        if(marker_array_yellow[i].id == pos_id) {
          marker = marker_array_yellow[i];
          google.maps.event.trigger(marker, 'click');
        }

      }
    }

  }
  jQuery('.js-close--modal').on('click', function() {
     infowindow.close();
  });

  url_string = window.location.href;
  
} //end function parseMarkers




jQuery(document).ready(function(){
  jQuery('.js-close--modal').on('click', function() {
    jQuery('.js-modal--store-info').css('display', 'none');
  });


  /*jQuery('.js-slider').slider({
    value: 12,
    min: 10,
    max: 14,
  
    //this updates the value of your hidden field when user stops dragging
    change: function(event, ui) {
      zoom = ui.value;
     

      if (zoom == 10) {
        jQuery('.js-range').text(10); 
         map.setZoom(14);
      } 
      else if (zoom == 11) {
        jQuery('.js-range').text(25);
        map.setZoom(13);
      }
       else if (zoom == 12) {
        jQuery('.js-range').text(50);
        map.setZoom(12);
      }
       else if (zoom == 13) {
        jQuery('.js-range').text(75); 
        map.setZoom(11);
      }
       else if (zoom == 14) {
        jQuery('.js-range').text(100);
        map.setZoom(10);
      }
   
      
    }
  });*/





    jQuery('.js-modal--store-info').on('click', '.js-btn-addtocart', function(){
        var url = jQuery('#addtocart').val();
        var year = jQuery('#year').val();
        var make = jQuery('#make').val();
        var model = jQuery('#model').val();
        var quantityControl = jQuery('.js-txt-quantity');
        var manufacturer = jQuery('#manufacturer').val();
        var description = jQuery('#description').val();
        var parttype = jQuery('#parttype').val();
        var productCode = jQuery('#partnumber').val();
        var lineCode = jQuery('#linecode').val();
        var storeId = jQuery(this).data('store-id');
        var sid = jQuery('#sid').val();
        var unitPrice = jQuery(this).data('unitprice');
        var tax = jQuery(this).data('tax');
        var deposit = jQuery(this).data('deposit');
        var buttonClicked = jQuery(this);
        
        quantityControl.removeClass('error');
        if (quantityControl.val() == 0) {
            quantityControl.addClass('error');
            return;
        }
        
        var successMsg = jQuery('.js-addtocard-msg.js-msg-success');
        var errorMsg = jQuery('.js-addtocard-msg.js-msg-success');
        var loading = buttonClicked.find('.js-loading');
        var text = buttonClicked.find('.js-text');
        
        successMsg.css('display', 'none');
        errorMsg.css('display', 'none');
        loading.removeAttr('style');
        text.css('display', 'none');
                    
        $.ajax({
            url:url,
            dataType: 'JSON',
            type: 'POST',
            data: {
                quantity : quantityControl.val(),
                productcode : productCode,
                linecode : lineCode,
                storeid : storeId,
                sid : sid,
                year : year,
                make : make,
                model : model,
                manufacturer : manufacturer,
                description : description,
                parttype : parttype,
                unitprice : unitPrice,
                tax : tax,
                deposit : deposit
            },
            success: function(data){
                if (data.error) {
                    successMsg.css('display', 'none');
                    errorMsg.removeAttr('style');
                    
                }  else if (data.count > 0) {
                    buttonClicked.css('background', '#313236');
                    successMsg.removeAttr('style');
                    updateCartNumbers();
                    jQuery('.js-container-content-step-4').parent().removeClass('inactive');
                    loading.css('display', 'none');
                    text.removeAttr('style');
                    
                }
            }
        });
    });
    function updateCartNumbers() {
      var count = 0;
      $.ajax({
          url:'/ajax/getCartItemCount',
          dataType: 'JSON',
          type: 'GET',
          success: function(data){
              count = data;
              //Total count in the menu
              var menuCount = jQuery('.js-menu-cart-count');
              if (count > 0){
                  if(menuCount.hasClass('hide')) {
                      menuCount.removeClass('hide')
                  }
                  if(count > 9){
                      menuCount.html("9+");
                  }
                  else{
                      menuCount.html(count);
                  }
              } else {
                  menuCount.html('');
                  menuCount.addClass('hide');
              }
          }
      });
  }
    
});