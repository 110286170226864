jQuery('document').ready(function(){   
   jQuery('.js-modify-criteria').on('click', function() {
        jQuery('.js-container-search-tool-toggle').slideToggle();
        jQuery(this).find('.fa').toggleClass('fa-plus-circle fa-minus-circle');
        if (Tools.IsMobile()) {
             jQuery('html, body').animate({
            scrollTop: jQuery(".js-modify-criteria").offset().top
            }, 500);
        }
        
        
    });
});