/* 
 * FORM BUILDER
 *
 * Comment l'utiliser
 * Form est un class builder. Il reconstruit presque automatiquement le formulaire de façon facilité le traitement de l'information.
 * Pour une utilisation avec:
 * new Form(document.querySelector("form"));
 * 
 * Il est possible d'utilisé des paramêtre et d'ajouter de nouvelle contrainte. Il est même conseillé d'en ajouté pour correspondre a vos besoin.
 * Cette version comprend les textes requis, les email et les choix simple. L'information pour contruire l'information est contenu dans les balise html de votre choix.
 * Il n'y a aucune action par defaut en cas d'erreur hormi d'empêcher la sommission du formulaire. Il est recommander d'ajouté vos action via les commandes du constructeur ou via les paramêtre. 
 * 
 * Note
 * Il peut être utiliser avec ou sans la validation du navigateur. 
 * Il est toutefois recommender d'ajouté le tag novalidate au formulaire pour ne pas afficher des messages d'erreurs en fonction du navigateur et des paramettres de langue des appareils.
 * La magorité des vérifications effectuées par les navigateurs ont déjà été soigneusement reconstruit ici.
 * Un input named default est aussi déconseillé. "default" est utilisé par la fonction Form() pour attribuer des événement par defaut à tous les input si ils ne sont pas spécifier dans les réglage.
 * 
 * Properties
 * Change error Message before new form // NOT RECOMMAND AFTER new Form();
 * > Form.Choises.prototype.NotBlank = function(){return "YOUR_MESSAGE";}; // ONLY WHEN AVAILABLE
 * > Constraint.NotBlank.prototype.getMessage = function(){return "YOUR_MESSAGE";}; // FOR ALL NEW FORM INPUT
 * 
 *  Create an occurrence
 *  > new Form(obj, OPTIONAL data = {
 * 		beforeSubmit : FUNCTION(DATA,BUILDER),
 * 		success : FUNCTION(EVENT),
 * 		error : FUNCTION(EVENT),
 * 		inputs: {
 * 			default OR "INPUT_NAME_PROPERTY":{
 * 				type: Form.FIELDTYPE (Default is detect by input type),
 * 				constraints: [
 * 					OBJECT WHIT PROPERTY bool test = function(string){} LIKE Constraint.NotBlank,
 * 					...
 * 				], (ADD constraint to Form.FIELDTYPE)
 * 				success: FUNCTION(input),
 * 				error: FUNCTION(input,error_code),
 * 			},
 * 			...
 * 		}
 * 
 * })
 *  < Form
 * 
*/

var Form = function(form,data) {
	if(!(this instanceof Form))
		throw new Error("Uncaught TypeError: Form is a construtor.");

	var inputs = {};
	var parameters = {
		form : form.name,
	};

	function twig(string){
		var a = (string.match(/\{.+?\}/g)||[]).map(function(str){return str.slice(1,-1)});
		a.forEach(function(k){
			if(parameters[k]!=undefined) string = string.replace('{'+k+'}',parameters[k]);
		});
		return string;
	}

	function INPUT(input,type,onSuccess,onError,onChange){
		this.value = undefined;
		var set = function(input){
			if( (input.type == "radio") && !input.checked){
				return ;
			}
			else if(input.type == "checkbox" && !input.name.contains('[]') && !input.checked){
				this.value = undefined;
				return;
			}
			else if(input.type == 'select-multiple'){
				var result = [];
				var options = input && input.options;
				var opt;
				for (var i=0, iLen=options.length; i<iLen; i++) {
					opt = options[i];

					if (opt.selected) {
					result.push(opt.value || opt.text);
					}
				}
				this.value = result.length?result:undefined;
				return;
			}
			else if(input.name.contains('[]')){
				var result = [];
				var options = input.form.querySelectorAll('[name="'+input.name+'"]');
				var opt;
				for (var i=0, iLen=options.length; i<iLen; i++) {
					opt = options[i];
					if(opt.type == "checkbox" && opt.checked) {
						result.push(opt.value || opt.text);
					}
					else if(opt.type != "checkbox"){
						result.push(opt.value);
					}
				}
				this.value = result.length?result:undefined;
				return;
			}
			this.value = input.value;
		}.bind(this);
		var change = function(e){
			set(e.target);
			if(typeof onChange == "function") (onChange.bind(form))(e);
			try{
				type.validate(this.value);
				this.success();
			}catch(error){
				this.error(error.message);
			}
		}.bind(this);
		this.addListener = function(input){
			// For new browser that make auto validation. 
			// Please note that if you not put novalidate on your form,the message can change depending of browsers, navigator language and other.
			// This method is inadvisable. Please add novalidate for more controle on your error text.
			input.addEventListener('invalid', function(e){
				e.preventDefault();
				this.error(input.validationMessage || 'Invalid value.');
			}.bind(this));
			set(input);
			input.addEventListener("change",change);
		}
		this.error = function(message){
			if(typeof onError == "function") (onError.bind(form))({target:input,error:message});
		}
		this.success = function(){
			if(typeof onSuccess == "function") (onSuccess.bind(form))({target:input});
		}
		this.addListener(input);
		return this;
	}

	function BUILDER(addTrigger){
		var inputs = {}
		form.querySelectorAll("input,textarea,select").forEach(function(e){
			// Reject input type search or input name empty;
			if(e.type == "search" || e.name == undefined || e.name == "")
				return;
			
			//Add Listener to multiple input radio or checkbox; 
			for(name in inputs){
				if(name == e.name){
					if(addTrigger == true)
						inputs[name].addListener(e);
					return;
				}
			}

			// Create form type
			var field = null;

			// Custom preset
			if(data != undefined &&
				data.inputs != undefined &&
				data.inputs[e.name] != undefined &&
				data.inputs[e.name].type != undefined){
				field = new data.inputs[e.name].type(e);
			}

			// Choice type
			else if(e.tagName.toUpperCase() == 'SELECT' || e.type == "radio" || e.name.contains('[]')){
				field = new Form.ChoicesType(e)
			}

			// Email type
			else if(e.type == "tel"){
				field = new Form.PhoneType(e)
			}

			// Email type
			else if(e.type == "email"){
				field = new Form.EmailType(e)
			}

			// Number type
			else if(e.type == "number"){
				field = new Form.NumberType(e)
			}

			//Default
			else {
				field = new Form.TextType(e)
			}

			// Add custom Constraint
			if( data != undefined &&
				data.inputs != undefined &&
				data.inputs[e.name] != undefined &&
				data.inputs[e.name].constraints != undefined
			){
				for(var k in data.inputs[e.name].constraints){
					if(typeof (data.inputs[e.name].constraints[k].test) == "function" ){
						field.addConstraint(data.inputs[e.name].constraints[k]);
					}
				}
			}
			
			if(addTrigger == true){
				// Check if success is defined
				var onError = undefined;
				var onSuccess = undefined;
				var onChange = undefined;

				if( data != undefined &&
					data.inputs != undefined &&
					data.inputs[e.name] != undefined &&
					typeof data.inputs[e.name].success == "function"
				){
					onSuccess = data.inputs[e.name].success;
				}
				else if( data != undefined &&
					data.inputs != undefined &&
					data.inputs.default != undefined &&
					typeof data.inputs.default.success == "function"
				){
					onSuccess = data.inputs.default.success;
				}
				// Check if error is defined
				if( data != undefined &&
					data.inputs != undefined &&
					data.inputs[e.name] != undefined &&
					typeof data.inputs[e.name].error == "function"
				){
					onError = data.inputs[e.name].error;
				}
				else if( data != undefined &&
					data.inputs != undefined &&
					data.inputs.default != undefined &&
					typeof data.inputs.default.error == "function"
				){
					onError = data.inputs.default.error;
				}

				// Check if change is defined
				if( data != undefined &&
					data.inputs != undefined &&
					data.inputs[e.name] != undefined &&
					typeof data.inputs[e.name].change == "function"
				){
					onChange = data.inputs[e.name].change;
				}
				else if( data != undefined &&
					data.inputs != undefined &&
					data.inputs.default != undefined &&
					typeof data.inputs.default.change == "function"
				){
					onChange = data.inputs.default.change;
				}

				inputs[e.name] = new INPUT(e,field,onSuccess,onError,onChange);
			}
			else 
				inputs[e.name] = field;	
		});
		if(addTrigger == true) {
			this.getData = function(name){
				if(name != undefined){
					name = twig(name);
					if(inputs[name] == undefined)
						throw new Error("Uncaught TypeError: This Form don't contain input[name=\""+name+"\"].");
					return inputs[name].value;
				}
				rsp = {};
				for(k in inputs){
					rsp[k] = inputs[k].value;
				}
				return rsp;
			}
			this.invalidate = function(name,message){
				inputs[name].error(message)
			}
			this.validate = function(name){
				inputs[name].success()
			}
		}
		else{
			this.get = function(name){
				return inputs[name];
			}
		}
		return this;
	}
	inputs = new BUILDER(true);

    this.validate = function(e){
		var builder = new BUILDER();
		if(data!=undefined && typeof data.beforeSubmit =="function")
			data.beforeSubmit(inputs.getData(),builder);
		
		//Start validation
		var error = false;
		var i_data = inputs.getData();
		for(var k in i_data){
			try{
				var field = builder.get(k)
				if(field!= undefined)
					field.validate(i_data[k])
				inputs.validate(k);
			}catch(log){
				inputs.invalidate(k,log.message);
				if(error == false) error = {};
				error[k] = log.message;
			}
		}
		if(error!= false){
			if(e instanceof Event) e.preventDefault();
			if(data!=undefined && typeof data.error =="function")
				data.error(e,error);
		}
		else if(data!=undefined && typeof data.success =="function")
			data.success(e);
	}
	
	this.getData = function(r){
		return inputs.getData(r);
	}

	if(data!=undefined && typeof data.error =="function")
		data.error = data.error.bind(form);

	if(data!=undefined && typeof data.success =="function")
		data.success = data.success.bind(form);

	form.addEventListener("submit",this.validate);
    return this;
};

/*
 * FORM.TYPE
 *
 * Form.type represent the input types. All input types create her base rules for validations like NotBlank if the attribute "required" is set or ISNAN if the type is number.
 * You can choose your Form.Type in Form builder Attributes.
 */
Form.TextType = function(input) {
	if(!(this instanceof Form.TextType))
    	throw new Error("Uncaught TypeError: Form.TextType is a construtor.");
	var constraints = [];

	this.validate = function(value){
		for(var i = 0; i< constraints.length; i++){
			constraints[i].test(value);
		}
	}
	this.addConstraint = function(constraint){
		constraints.push(constraint)
	}

	if(input!=undefined){
		if(input.required){
			this.addConstraint(new Constraint.NotBlank());
		}
		if(input.maxlength){
			if(input.minlength)
				this.addConstraint(new Constraint.Length(parseFloat(input.minlength), parseFloat(input.maxlength)));
			else 
				this.addConstraint(new Constraint.Length(parseFloat(input.maxlength)));
		}
		if(input.dataset && input.dataset.confirm){
			this.addConstraint(new Constraint.Confirm(input.dataset.confirm));
		}
	}
	
	return this;
};

Form.NumberType = function(input) {
	if(!(this instanceof Form.NumberType))
		throw new Error("Uncaught TypeError: Form.NumberType is a construtor.");
	var herit = new Form.TextType(input);
	this.validate = herit.validate
	this.addConstraint = herit.addConstraint;

	this.addConstraint(new Constraint.ISNAN());

	if(input!=undefined){
		if(input.min){
			this.addConstraint(new Constraint.Min(parseFloat(input.min)));
		}
		if(input.max){
			this.addConstraint(new Constraint.Max(parseFloat(input.max)));
		}
	}
	return this;
};

Form.EmailType = function(input) {
	if(!(this instanceof Form.EmailType))
		throw new Error("Uncaught TypeError: Form.EmailType is a construtor.");

	var herit = new Form.TextType(input)
	this.validate = function(v){
		try{herit.validate(v);}
		catch(log){
			if(log.message == Constraint.Pathern.prototype.getMessage()) log.message = this.Pathern();
			throw new Error(log.message);
		}
	}
	this.addConstraint = herit.addConstraint;
	this.addConstraint(new Constraint.Pathern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))
	
	return this;
};
Form.EmailType.prototype.Pathern = function(){return "Please enter a valid email.";};

Form.PhoneType = function(input){
	if(!(this instanceof Form.PhoneType))
		throw new Error("Uncaught TypeError: Form.PhoneType is a construtor.");

	var herit = new Form.TextType(input)
	//this.validate = herit.validate
	this.validate = function(v){
		try{herit.validate(v);}
		catch(log){
			if(log.message == Constraint.Pathern.prototype.getMessage()) log.message = this.Pathern();
			throw new Error(log.message);
		}
	}
	this.addConstraint = herit.addConstraint;
	this.addConstraint(new Constraint.Pathern(/(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?/img))

	return this;
};
Form.PhoneType.prototype.Pathern = function(){return "Please enter a valid phone number.";};

Form.ChoicesType = function(input) {
	if(!(this instanceof Form.ChoicesType))
		throw new Error("Uncaught TypeError: Form.ChoicesType is a construtor.");

	var herit = new Form.TextType(input);
	this.validate = function(v){
		try{herit.validate(v);}
		catch(log){
			if(log.message == Constraint.NotBlank.prototype.getMessage()) log.message = this.NotBlank();
			throw new Error(log.message);
		}
	}
	this.addConstraint = herit.addConstraint;
	return this;
};
Form.ChoicesType.prototype.NotBlank = function(){return "Please make your selection.";};
/*
 * CONSTRAINTS
 *
 * Constaint is the rules all input can optionnaly apply depending of type and attributes.
 * It is possible to add constraint to input via Form parameter. It is also possible to edit custom Constraint to Formbuilder before submit the form. 
 */
Constraint = function(){throw new Error("Constraint is not a function")};

Constraint.NotBlank = function(){
	if(!(this instanceof Constraint.NotBlank))
		throw new Error("Uncaught TypeError: Constraint.NotBlank is a construtor.");

	this.test = function(value){
		if(value == undefined || value == null || value == "")
			throw new Error(this.getMessage());
	}
	return this;
};
Constraint.NotBlank.prototype.getMessage = function(){return "Please fill out this field.";};

Constraint.Confirm = function(input){
	if(!(this instanceof Constraint.Confirm))
		throw new Error("Uncaught TypeError: Constraint.Confirm is a construtor.");
	input = document.querySelector('[name="'+input+'"]')
	if(input == undefined || input == null)
		throw new Error("Uncaught TypeError: Constraint.Confirm first attribute is wrong.");
	this.test = function(value){
		if(input.value != value)
			throw new Error(this.getMessage());
	}
	return this;
};
Constraint.Confirm.prototype.getMessage = function(){return "This field did not match is referer.";};

Constraint.Length = function(min,max){
	if(!(this instanceof Constraint.Length))
		throw new Error("Uncaught TypeError: Constraint.Length is a construtor.");
	if(max == undefined){
		max = min;
		min = 0;
	}
	this.test = function(value){
		if((value != undefined && value != null && value != "") && value.length < min)
			throw new Error(this.getMessageShort());
		if((value != undefined && value != null && value != "") && value.length > max)
			throw new Error(this.getMessageLong());
	}
	return this;
};
Constraint.Length.prototype.getMessageShort = function(){return "The message you have entered is too short.";};
Constraint.Length.prototype.getMessageLong = function(){return "The message you have entered is too long.";};

Constraint.Pathern = function(pathern){
	if(!(this instanceof Constraint.Pathern))
		throw new Error("Uncaught TypeError: Constraint.Pathern is a construtor.");

	this.test = function(value){
		var a = (value != undefined && value != null && value != "");
		var b = !(new RegExp(pathern)).test(value);
		if( a && b ){
			throw new Error(this.getMessage());
		}
	}
	return this;
};
Constraint.Pathern.prototype.getMessage = function(){return "The message doesn't Match the Pathern.";};

Constraint.ISNAN = function(){
	if(!(this instanceof Constraint.ISNAN))
		throw new Error("Uncaught TypeError: Constraint.ISNAN is a construtor.");
	this.test = function(value){
		if((value != undefined && value != null && value != "") && isNaN(parseFloat(value)))
			throw new Error(this.getMessage());
	}
	return this;
};
Constraint.ISNAN.prototype.getMessage = function(){return "Please set a number.";}

Constraint.Min = function(min){
	if(!(this instanceof Constraint.Min))
		throw new Error("Uncaught TypeError: Constraint.Min is a construtor.");
	this.test = function(value){
		var v = parseFloat(value);
		if(isNaN(v)) v = 0;
		if((value != undefined && value != null && value != "") && v<min )
			throw new Error(this.getMessage());
	}
	return this;
};
Constraint.Min.prototype.getMessage = function(){return "The number you have entered is too low.";};

Constraint.Max = function(max){
	if(!(this instanceof Constraint.Max))
		throw new Error("Uncaught TypeError: Constraint.Max is a construtor.");
	this.test = function(value){
		var v = parseFloat(value);
		if(isNaN(v)) v = 0;
		if((value != undefined && value != null && value != "") && v>max )
			throw new Error(this.getMessage());
	}
	return this;
};
Constraint.Max.prototype.getMessage = function(){return "The number you have entered is too hight.";};

// END FORM BUILDER

// POLYFILL REQUIRED
if (!Element.prototype.matches)
    Element.prototype.matches = Element.prototype.msMatchesSelector || 
                                Element.prototype.webkitMatchesSelector;
if (!Element.prototype.closest)
    Element.prototype.closest = function(s) {
        var el = this;
        if (!document.documentElement.contains(el)) return null;
        do {
            if (el.matches(s)) return el;
            el = el.parentElement || el.parentNode;
        } while (el !== null); 
        return null;
    };

if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = function (callback, thisArg) {
        thisArg = thisArg || window;
        for (var i = 0; i < this.length; i++) {
            callback.call(thisArg, this[i], i, this);
        }
    };
}
if (typeof String.prototype.contains === 'undefined') { String.prototype.contains = function(it) { return this.indexOf(it) != -1; }; }


function popupForm(form,action,parameters){
    if(!(this instanceof popupForm))
        throw new Error("Uncaught TypeError: popupForm is a construtor");
    if(action == undefined || action == null || action == "")
        action = __('Send');
    var element = document.createElement("div");
    element.className="c--popup c--popup-choices js-popup";
    var content =
    '<div class="c--popup----bg"></div>'+
	'<div class="c--popup-box c--popup-form">'+
		'<a href="#" class="c--popup-close js--close"></a>'+
        '<div class="c--popup-content">'+'</div>'+
        '<div class="c--popup-footer">'+
            '<a class="js--confirm btn confirm">'+action+'</a>'+
        '</div>'+
    '</div>';
    element.innerHTML=content;
    element.querySelector('.c--popup-content').appendChild(form);

    this.toggle = function(){
        if(element.classList.contains("js--is-open"))
           return  this.close();
        else
            return this.open();
    }
    this.open = function(){
        element.classList.add("js--is-open");
        return this;
    }
    this.close = function(die){
        element.classList.remove("js--is-open");
        return this;
    }
    this.die = function(){
        element.parentElement.removeChild(element);
        return this;
	}
	this.load = function(){
		element.classList.add("js--is-loading");
		element.classList.remove("js--is-open");
	}
    
    document.querySelector("body").appendChild(element);
    $(element).on("click",".js--close",function(e){
        e.preventDefault()
        this.close();
        this.die();
    }.bind(this));

    $(element).on("click",".js--confirm",function(e){
		var event = new Event('submit'); form.dispatchEvent(event);
	}.bind(this));
	
	var params = {
		success: function(e){
			if(parameters!=undefined && parameters.success!=undefined) (parameters.success.bind(form))(e);
			if(parameters!=undefined && parameters.autoclose!=false){
				this.close();
				this.die();
			}
		}.bind(this),
		error : function(e){
			e.preventDefault();
		}.bind(this),
	}
	if(parameters!=undefined && parameters.inputs!=undefined) params.inputs = parameters.inputs;

	var builder = new Form(form,params);

	if(parameters!=undefined && parameters.beforeOpen!=undefined) (parameters.beforeOpen.bind(form))(element);
    this.toggle();
    return this;
}
