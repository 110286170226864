jQuery( document ).ready(function() {

    jQuery(document).on("click",".js--fake_input_control .more,.js--fake_input_control .less",function(){
        var c = jQuery(this) 
        var input = c.closest(".js--fake_input_number").find("input[type=number]");
        var val = parseInt(input.val());
        var min = parseInt(input.attr("min"));
        var max =  parseInt(input.attr("max"));
        var step =  parseInt(input.attr("step"));

        step = isNaN(step)?1:step;
        if(c.hasClass("more") && (isNaN(max) || val<max) )
            val = val + step;
        else if(isNaN(min) || val>min)
            val =val - step;
        input.val(val);
        input.trigger("change")

    });

    jQuery('.js-cta-form').on('click','.js-open',function(e){
        e.preventDefault();
        $(this).closest('.js-cta-form').toggleClass('is-open')
        $(this).closest('.js-cta-form').find("form").slideToggle();
        $(this).closest('.js-cta-form').find("form").next().slideUp(function(){jQuery(this).remove()})
        if($('.js-cta-form').hasClass('is-open'))
        $('html').animate({
            scrollTop: $(this).closest('.js-cta-form').offset().top
        }, 1000);
        setTimeout(function(){
            $('#registration_corporative_name').focus();
        },1000)

    });


    function help_form(subject, message){
        if(typeof subject != 'string' ) subject = '';
        if(typeof message != 'string' ) message = '';

        var form = $('<form class="container-form p1" action="/" method="post" novalidate="novalidate" style="">'+
            '<div class="form-group mb2" method="post">'+
                '<label class="text-left" for="registration_corporative_name">'+ __('What is your issue?') + '</label>'+
                '<input class="full" type="text" name="subject" id="registration_corporative_name" placeholder="" maxlength="250" required="" value="'+subject+'">'+
                '<div class="help-block"></div>'+
            '</div>'+
            '<div class="form-group">'+
                '<label class="text-left" for="registration_corporative_name">'+ __('Your comment :')+'</label>'+
                '<textarea class="full" name="message" id="" rows="5" required="">'+message+'</textarea>'+
                '<div class="help-block"></div>'+
            '</div>'+
            '<input type="hidden" name="config_name" value="Unitrans ">'+
            '<input type="hidden" name="config_email_admin" value="swerbrouck@unitrans.ca ">'+
        '</form>')[0];

        var popup = new popupForm(form,undefined,{
            autoclose: false,
            success:function(e){
                e.preventDefault();
                var form = jQuery(this);
                var data = form.serialize();
                jQuery.ajax({
                    url: '/ajax/itemNotFound',
                    type: 'POST',
                    data: data,
                    success: function() {
                        jQuery('.js-cta-form').find('input,textarea').each(function(el){
                            $(this).val('');
                            // Trigger change manualy because Form work on Vanilla.js
                            var event = new Event('change'); this.dispatchEvent(event);
                            $(this).parent().children(".help-block").empty();
                        })
                        $('.js-cta-form').removeClass('is-open')
                        form.slideUp().after('<p style="background: #8CBE4F; padding: 5px; display: none; ">'+__('Your request has been sent successfully. A manager will answer you shortly.')+'</p>').next().slideDown();
                        setTimeout(popup.die,3500)
                    }
                });
            },
            inputs:{
                default:{
                    error: function(e){
                        $(e.target).parent().addClass("error").children(".help-block").html(__(e.error));
                    },
                    success: function(e){
                        $(e.target).parent().removeClass("error").children(".help-block").empty();
                    }
                }
            }
        });
        return form;
    }
    
    jQuery(document).on('click','.js--make-request',function(e){
        e.preventDefault();
        var subject = '';
        var message = '';
        if($(this).closest('.js-part').length){
            subject = __('I want command the part #%s').replace(/%s/g,$(this).closest('.js-part').data('partnumber'));
            message = $(this).closest('.js-part').data('manufacturer')+'\n'+$(this).closest('.js-part').data('parttypename')+"\n#"+$(this).closest('.js-part').data('partnumber')+"\n";
        }

        var form = help_form(subject,message);

        
    })

    if( $('.js-step3').length && !$('.js-part').length ){
        var form = help_form(__('0 Parts found for: ') + $('.js-container-partial--step-breadcrumb .breadcrumb__alpha-table').first().text().replace('>','').trim());
    }

    jQuery('.js-button-start').click(function(){
        jQuery('.js-form').fadeIn(); 
        jQuery('.js-start, .js-container__anchors').fadeOut(); 
    });
    jQuery('.js--btn-close-form').click(function(){
        jQuery('.js-form').fadeOut(); 
        jQuery.when(jQuery('.js-start, .js-container__anchors').fadeIn()).done(function(){
            jQuery('.js-step-1, .js-step-2, .js-step-3, .js-step-4, .js-confirmation').hide();
            jQuery('.js-steps').show();
        }); 
        
    });
     jQuery('.js-link-brand-name').click(function(){
        jQuery('.select2').fadeOut(); 
        jQuery('.js-brand-name').fadeIn(); 
    });
    jQuery('.js-button-step').click(function() {
        if(!jQuery(this).parents().hasClass('disabled')) {
            step_current = jQuery(this).parent().parent().parent().data('step');
            if (isNaN(step_current)) {
                step_current = 0;
            }   
            step_next = step_current + 1
            jQuery('.js-steps, .js-step-' + step_current).fadeOut(); 
            jQuery('.js-step-' + step_next).fadeIn(); 
        }
    });

    jQuery('.js-select2, .js-domain, .js-brand-name').on("change, keyup", function () { 
        if (jQuery('.js-domain').val() != '' && (jQuery('.js-select2').val() != '' || jQuery('.js-brand-name').val() != '')) {
            jQuery('.js-step-1 .disabled').removeClass('disabled');
        }
     });
    jQuery('.js-btn--agreement').click(function(e){
        e.preventDefault;
        var email = jQuery('.js-email').val();
        var token = jQuery('.js-token').val();
         jQuery.ajax({
            url: '/wp-content/themes/netcomautomotive/partials/validation.php',
            type: 'POST',
            data: {
                'email' : email,
                'token' : token
            },
            success: function(response) {
                if(response == 'done')
                    jQuery('.js-confirmation').removeClass('hide');
            }
        });
    });

 
    jQuery('.js-box--validation').on('scroll', function()  {
        if(jQuery(this).scrollTop() + jQuery(this).innerHeight()>=jQuery(this)[0].scrollHeight) {
          jQuery('.js-btn--agreement').removeClass('disabled');
        }
    });

    jQuery('.js-step-3 input').on("change, keyup", function () { 
        if (jQuery('input[name="brand_first_name"]').val() != '' && 
            jQuery('input[name="brand_last_name"]').val() != '' &&
            jQuery('input[name="brand_title"]').val() != '' &&
            jQuery('input[name="brand_email"]').val() != '' &&
            jQuery('input[name="brand_phone"]').val() != '' &&
            jQuery('input[name="catalog_first_name"]').val() != '' && 
            jQuery('input[name="catalog_last_name"]').val() != '' &&
            jQuery('input[name="catalog_title"]').val() != '' &&
            jQuery('input[name="catalog_email"]').val() != '' &&
            jQuery('input[name="catalog_phone"]').val() != ''
            ) { 
            jQuery('.js-step-3 .disabled').removeClass('disabled');
        }
     });

     jQuery('input[type=file]').on("change", function () { 
        if (jQuery('#fileToUploadAces').val() != '' && jQuery('#fileToUploadLogo').val() != '' ) {
            jQuery('.js-step-4 .disabled').removeClass('disabled');
        }
     });


    jQuery('.js-btn--add-brand').on('click', function(e){
        e.preventDefault();
        brand_first_name    =  jQuery('input[name=brand_first_name]').val();
        brand_last_name    =  jQuery('input[name=brand_last_name]').val();
        brand_title   =  jQuery('input[name=brand_title]').val();
        brand_email   = jQuery('input[name=brand_email]').val();
        brand_phone   = jQuery('input[name=brand_phone]').val();

        catalog_first_name    =  jQuery('input[name=catalog_first_name]').val();
        catalog_last_name    =  jQuery('input[name=catalog_last_name]').val();
        catalog_title   = jQuery('input[name=catalog_title]').val();
        catalog_email   = jQuery('input[name=catalog_email]').val();
        catalog_phone   = jQuery('input[name=catalog_phone]').val();

        plan = jQuery('input[name=plan]:checked').val();
        plan_id = jQuery('input[name=plan]:checked').data('level'); 
        domain = jQuery('input[name=domain]').val();
        brand_name = jQuery('.js-select2').val();
        brand_id = jQuery('.js-select2 option[value="'+brand_name+'"]').data('id');
        lang = jQuery('html').prop('lang');
        hp_email =  jQuery('.hp-email').val();
       
      
        var file_aces = document.getElementById('fileToUploadAces').files[0]; 
        var file_pies = document.getElementById('fileToUploadPies').files[0]; 
        var file_logo = document.getElementById('fileToUploadLogo').files[0]; 
     

        var data = new FormData();

  
        data.append('brand_first_name' , brand_first_name);
        data.append('brand_last_name' , brand_last_name);
        data.append('brand_title' , brand_title);
        data.append('brand_email' , brand_email);
        data.append('brand_phone' , brand_phone);
        data.append('catalog_first_name' , catalog_first_name);
        data.append('catalog_last_name' , catalog_last_name);
        data.append('catalog_title' ,  catalog_title);
        data.append('catalog_email' , catalog_email);
        data.append('catalog_phone' ,  catalog_phone);
        data.append('plan' , plan);
        data.append('plan_id' , plan_id);
        data.append('domain' , domain);  
        data.append('brand_id' , brand_id);
        data.append('brand_name' , brand_name);
        data.append('lang' , lang);
        data.append('netcom_submit' , 'send');
        data.append('fileToUploadAces', file_aces);
        data.append('fileToUploadPies', file_pies);
        data.append('fileToUploadLogo', file_logo);        
        data.append('hp-email', hp_email); 


        jQuery.ajax({
            url: '/wp-content/themes/netcomautomotive/partials/upload.php',
            cache: false,
            contentType: false,
            processData: false,
            type: 'POST',
            data: data,
            success: function(response) {
                console.log(response);
               if (response == 'sent') {
                    jQuery('.js-step-4').fadeOut();
                    jQuery('.js-confirmation').fadeIn();
                    jQuery('.js-confirmation-email').text(brand_email);
               }
               
            }
        }); 
     
      
        return false;
    });
});  
