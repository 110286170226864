$(function () {
    //Event listeners
    GetFooterContactFormContainer().on('submit', '#footer-contact-form', FooterContactFormSubmitHandler);
})
//event handlers
function FooterContactFormSubmitHandler(event) {
    event.preventDefault();
    PostFormData($(this).prop('action'), new FormData($(this)[0]), RefreshFooterContactForm)
}

//DOM GET
function GetFooterContactFormContainer() {
    return $("#footer-contact-form-container");
}
//DOM SET
function RefreshFooterContactForm(newForm) {
    GetFooterContactFormContainer().html(newForm);
}

//DAL
function PostFormData(url, formData, callBack) {
    $.ajax({
        url: url,
        data: formData,
        processData: false,
        contentType: false,
        type: 'POST',
        success: callBack
    });
}