jQuery(document).ready(function(){

        $(document).on('click', function (e) {
            if($(e.target).closest('.container-partial--my-profile , .fa-user').length == 0){
                jQuery('.container-partial--my-profile').css({display:'none!important'}).animate({opacity:'0%'}, { duration: 200, queue: false }); 
                jQuery('.container-partial--my-profile').removeClass('is-open');
                jQuery('.container-partial--my-profile--overlay').removeClass('is-open');
                jQuery('body').removeClass("modal-open");
            }
        });
        jQuery(document).on('click','.js-user-pop-up', function(e) {
            e.preventDefault();
            if(jQuery('.container-partial--my-profile').hasClass('is-open')){
                jQuery('.container-partial--my-profile').css({display:'none!important'}).animate({opacity:'0%'}, { duration: 200, queue: false }); 
                jQuery('.container-partial--my-profile').removeClass('is-open');
                jQuery('.container-partial--my-profile--overlay').removeClass('is-open');
                jQuery('body').removeClass("modal-open");
            }
            else{
                jQuery('.container-partial--my-profile').css({display:'block!important'}).animate({opacity:'100%'}, { duration: 200, queue: false }); 
                jQuery('.container-partial--my-profile').addClass('is-open');
                jQuery('.container-partial--my-profile--overlay').addClass('is-open');
                jQuery('body').addClass("modal-open");
            }
            toggleOverlay();
        }); 

    jQuery(document).on('click','.js--impersonofication', function(e) {
        e.preventDefault();

        var url = $(this).find('a').attr('href');
        if(!url) return;

        var form = $('<form class="container-form p1" action="'+url+'" method="GET" novalidate="novalidate" style="">'+
            '<div class="form-group" method="post">'+
                '<label class="c--popup-title text-left" for="c--popup-title registration_corporative_name">'+ __('Select a customer to impersonate') + '</label>'+
                '<p class="color--secondary my1">'+__('Type the first digits of the business name or provide the full customer code or phone number.')+'</p>'+
                '<div class="selectbox selectbox__model">'+
                    '<select data-default-value="" class="model js-select2-ajax" data-url="'+url+'" name="set"><option value="'+$(this).data('actual-customer-id')+'">'+__('Search')+'</option></select>'+'</div>'+
                '<div class="help-block"></div>'+
            '</div>'+
        '</form>')[0];

        var popup = new popupForm(form,__('Impersonate'),{
            autoclose: false,
            language: window.Translator.getLang(),
            beforeOpen:function(){
                $(this).find('.js-select2-ajax').select2({
                    //dropdownParent: $(this),
                    ajax: {
                        dataType: 'json',
                        url: function(param){
                            return this.data('url');
                        },
                        processResults: function (data) {
                            return data;
                        }
                    }
                });
            },
            success:function(e){
                $(this).submit();
                popup.load();
            },
            inputs:{
                default:{
                    error: function(e){
                        $(e.target).parent().addClass("error").children(".help-block").html(__(e.error));
                    },
                    success: function(e){
                        $(e.target).parent().removeClass("error").children(".help-block").empty();
                    }
                }
            }
        });
        //return form;
    });

    jQuery(document).on('change','.js--select-impersonofication', function(e) {
        //console.log($(this).val());
        var val = $(this).val()
        window.location.href=this.dataset['url']+'?set='+(val!=null?val:'');
    })

      jQuery(document).on('click','.js-link-help, .js-close-help', function(e) {
            e.preventDefault();
            jQuery('.js-container-partial--subscription,  .js-container-partial--my-garage, .js-container-partial--my-profile').fadeOut(); 
        if(!jQuery('.js-toggle-menu').hasClass('is-open')){ 
            jQuery('.js-container-partial--help').css( 'right', '50px');  
            jQuery('.js-container-partial--help').fadeToggle(); 
        }else{
            jQuery('.nav__container').animate({opacity:'0'}, { duration: 200, queue: false });
            jQuery('.js-container-partial--help').css({display: 'inline-block', right: '-100%', opacity: '1'}).animate({right:'0%'}, { duration: 200, queue: false }); 
        }
    });

     jQuery(document).on('click','.js-link-speed-entry, .js-close-speed-entry', function(e) {
        event.preventDefault();
        jQuery('.js-container-partial--subscription, .js-container-partial--my-garage, .js-container-partial--help, .js-container-partial--my-profile').fadeOut();
        //jQuery('.js-container-speed-entry').fadeToggle();
      });

  

    jQuery(document).scroll(function(e){
        if(jQuery('.js-container-partial--my-garage').css('display') == 'block' && !Tools.IsMobile()){
            jQuery('.js-container-partial--my-garage, .js-container-partial--my-profile').fadeOut();
        }
          if(jQuery('.js-container-partial--help').css('display') == 'block' ){
            jQuery('.js-container-partial--help').fadeOut();
        }
        if(jQuery('.js-nav__container').hasClass('is-open') && Tools.IsMobile()){
            jQuery('.js-nav__container, .js-toggle-menu').removeClass('is-open');
        }
         if(jQuery('.js-container-partial--subscription').css('display') == 'block' ){
            jQuery('.js-container-partial--subscription').fadeOut();
        }
            
    });

    jQuery(document).on('click','.js-button-back-menu',function() {
        container_parent = jQuery(this).parent();
       jQuery('.nav__container').animate({opacity:'1', left: '0'}, { duration: 200, queue: false }); 
       container_parent .animate({right:'-100%'},  function(){
       		container_parent .css({display: 'none'});
       }); 
    });  

    if(jQuery('.js-toggle-menu').length)
    jQuery(document).on('click','.js-link-subscription, .js-close-subscription', function(e) {
        e.preventDefault();
        jQuery('.js-container-partial--my-garage, .js-container-partial--my-profile, .js-container-partial--help').fadeOut();
        if(!jQuery('.js-toggle-menu').hasClass('is-open')){ 
            var offset_subscription = jQuery(".js-link-subscription").offset();
            jQuery('.js-container-partial--subscription').css( { left: offset_subscription.left -40 }); 
            jQuery('.js-container-partial--subscription').fadeToggle();
        }else{
            jQuery('.nav__container').animate({opacity:'0'}, { duration: 200, queue: false });
            jQuery('.js-container-partial--subscription').css({display: 'inline-block', opacity: '1'}).animate({ right: '0%'}, { duration: 200, queue: false }); 
        }

    });


    jQuery('body').click(function(event) {
        if (!Tools.IsMobile()) {
            var target = jQuery(event.target);
             if (!target.parents(".js-container-partial--subscription").length && !target.parents(".js-nav__container").length && !target.parents(".js-container-partial--help").length  && !target.parents(".js-container-speed-entry").length && !target.parents(".js-container-partial--my-garage").length) {
                   jQuery('.js-container-partial--my-garage, .js-container-partial--my-profile, .js-container-partial--help,  .js-container-partial--subscription').fadeOut();
            }
        }
    });


    // Hide Header on on scroll down
    var didScroll;
    var lastScrollTop = 0;
    var delta = 5;
    var navbarHeight = jQuery('.js-site-header').outerHeight();

    jQuery(window).scroll(function(event){
     
        didScroll = true;
    });
    
    setInterval(function() {

        if (didScroll && jQuery(window).width() < 768 && Tools.IsMobile()) {
            hasScrolled();
            didScroll = false;
        }
    }, 250);


    function hasScrolled() {
        var st = jQuery(this).scrollTop();
        
        // Make sure they scroll more than delta
        if(Math.abs(lastScrollTop - st) <= delta)
            return;
        
        // If they scrolled down and are past the navbar, add class .nav-up.
        // This is necessary so you never see what is "behind" the navbar.
        if (st > lastScrollTop && st > navbarHeight){
            // Scroll Down
            jQuery('.js-site-header').removeClass('nav-down').addClass('nav-up');
        } else {
            // Scroll Up
            if(st + jQuery(window).height() < jQuery(document).height()) {
                jQuery('.js-site-header').removeClass('nav-up').addClass('nav-down');
            }
        }
        
        lastScrollTop = st;
    }


    function toggleOverlay() { 
        if (jQuery('.js-toggle-menu').hasClass('is-open')) {
            jQuery('.js-mobile-overlay').fadeIn();
        } else {
            jQuery('.js-mobile-overlay').fadeOut(); 
        }
    }

  

});