jQuery(document).ready(function(){
	
	stores.forEach(function(object){
		url = window.location.href;
    	if (url.indexOf(object.id) !== -1) {
    		jQuery('.js-store-name').html(object.name);
    		jQuery('.js-store-phone').html(object.phone);
    		jQuery('.js-store-fax').html(object.fax);
    		jQuery('.js-store-email').html(object.email);
    		jQuery('.js-store-address').html(object.address);
    		jQuery('.js-store-city').html(object.city);
    		jQuery('.js-store-province').html(object.province);
    		jQuery('.js-store-postal').html(object.postal_code);
    		jQuery('.js-store-country').html(object.country);
    		jQuery('.js-store-facebook').html(object.facebook_link);
    		jQuery('.js-store-website').html(object.website_link);
            jQuery('.js-button--direction').attr('onclick', 'window.open("http://maps.google.com/maps?daddr=' + object.lat + ',' + object.long + '&amp;saddr=")');
            jQuery('.js-link-map').attr('href', 'http://maps.google.com/maps?daddr=' + object.lat + ',' + object.long + '&amp;saddr=');
            if (lang == 'fr') {
                jQuery('.js-store-description').html(object.description_fr);
            } else {
                jQuery('.js-store-description').html(object.description_en); 

            }
          
            initMapStore(object.lat, object.long);
    	}
	});
});
