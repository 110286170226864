function GrouptypeButton(element){
    var _ = this;
    var cta;
    var blackbox;
    var grouptype_container;
    var id;
    function _construct(element){
        cta = $(element).find('.js-cta');
        id = $(element).data('id');
        blackbox = $(element).find('.js-blackbox');

        $(document).on('click',function(e){
            var btn = $(e.target).closest('.grouptype-btn-container');
            if(btn.length !== 0 && btn.get(0) == element){
                if($('.js-grouptype-controller').attr('data-step2') == 1){
                    grouptype_container.HideVehicleTitle();
                    _.ShowGrouptypeContainer();
                }
                else{
                    _.ShowGrouptypeContainer();
                }
            }
            else{
                _.HideBlackBox();
            }
        });

        $(element).find('.js-tab').on('click',function(e){
            e.preventDefault();
            _.ShowGrouptypeContainer();
        });
    }

    _.ShowBlackBox = function(){
        cta.addClass('display-none');
        blackbox.removeClass('display-none');
    }

    _.HideBlackBox = function(){
        blackbox.addClass('display-none');
        cta.removeClass('display-none');
    }

    _.SetGrouptypeContainer = function(container){
        grouptype_container = container;
    }
    _.ShowGrouptypeContainer = function(){
        grouptype_container.Show();

    }

    _.GetID = function(){
        return id;
    }

    _construct(element);
}