jQuery('document').ready(function() {
    jQuery('.js-sidebar-menu-icon').on('click', function(){
        jQuery('.js-sidebar').toggleClass('active');
        header_height = jQuery('header').height();
        document_height = jQuery(document).height();
        jQuery('.js-sidebar').css('height', document_height - header_height  +'px' );
        jQuery(this).find('.fa').toggleClass('fa-bars fa-angle-times');
    });
    jQuery('body').click(function(event) {
        if (!$(event.target).closest('.js-sidebar, .js-sidebar-menu-icon .fa').length && jQuery('.js-sidebar-menu-icon .fa-angle-times').length) {
            jQuery('.js-sidebar').toggleClass('active');
            jQuery('.js-sidebar-menu-icon').find('.fa').toggleClass('fa-bars fa-angle-times');
        } 
    }); 
});