function footerPosition() {
    left_side_height = jQuery('.js-left-side-height').height();
    //console.log(left_side_height);
    window_height = jQuery(window).height();
    header_height = jQuery('header').height();
    footer_height = jQuery('footer').height();
    breadcrumb_height = jQuery('.js-container-partial--step-breadcrumb').height();
    referrer = window.location.href;
    

    if (left_side_height > window_height) {
        height = left_side_height 
    } else if (referrer.indexOf('etape4') >= 0 || referrer.indexOf('step4') >= 0 || referrer.indexOf('panier-dachat') >= 0 || referrer.indexOf('cart') >= 0) {
        height = window_height - (footer_height + header_height + breadcrumb_height);
    } else {
        height = window_height - (footer_height + header_height);
    }
    

    if (height < 800 ) {
       height = 850;
    }
    
    //jQuery('.js-height-resize').css('min-height', height +'px' );
    
}
   
