Number.prototype.toMoney = function(){
    return this.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, "&nbsp;");
}

jQuery('document').ready(function() {
    var isUpdating = false;
    updateCartNumbers();

    jQuery('.js-cart .item-list form').on('change', 'input[type=text]',function(e){
        $.ajax({
            url: '/ajax/updateComment/', 
            dataType: 'JSON',
            type: 'POST',
            data: {
                group : $(this).closest('form').find('[name=group]').val(),
                po : $(this).closest('form').find('[name=po]').val(),
                comment : $(this).closest('form').find('[name=comment]').val()
            },
            success: function(rsp) {
                console.log('rsp');
            }
        });
    })

     jQuery('.js-cart').on('change', '.js-product-quantity', function() {
        var total = 0;
        var qty_update = jQuery(this).val();
        var unitprice = jQuery(this).closest(".js-item").data('unitprice');
        var subtotal = (unitprice * qty_update).toFixed(2);
        var $this = jQuery(this);
        $this.closest(".js-item").data('quantity', qty_update);
        $this.closest(".js-item").find('.js-item-total').data('subtotal', subtotal).html(__('$ %s').replace(/%s/g,subtotal) );


        var grpTot = 0;
        $this.closest('.item-list').find(".js-item").each(function(){
            grpTot += parseFloat($(this).find('.js-item-total').data('subtotal'));
        })
        $this.closest('.item-list').find(".item-label").last().html( __('$ %s').replace(/%s/g,grpTot.toFixed(2)) )

        var productcode = $this.closest(".js-item").data('partnumber');
        var linecode = $this.closest(".js-item").data('linecode');
        var year = $this.closest(".js-item").data('year');
        var make = $this.closest(".js-item").data('make');
        var model = $this.closest(".js-item").data('model');
        var id = $this.closest(".js-item").data('id');
        var manufacturer = $this.closest(".js-item").find('.js-item-manufacturer').html();
        var description = $this.closest(".js-item").find('.js-item-description').html();
        var parttype = $this.closest(".js-item").find('.js-item-parttype').html();
        var storeid = $this.data('storeid');
        var sid = $this.data('sid');
        var unitPrice = $this.closest(".js-item").data('unitprice'); 
        var tax = $this.closest(".js-item").data('tax');
        var deposit = $this.closest(".js-item").data('deposit');
        $this.addClass('hide');
        $this.siblings(".fa").removeClass('hide');
        var cartOnly = !$this.closest(".item-list").find("input[name='cart_temp[]']").is(":checked");
        $.ajax({
            url: '/ajax/deleteitem/', 
            dataType: 'JSON',
            type: 'POST',
            data: {
                id : id
            },
            success: function() {
                var $data = {
                    quantity : qty_update,
                    productcode : productcode,
                    linecode : linecode,
                    year : year,
                    make : make,
                    model : model,
                    manufacturer : manufacturer,
                    parttype : parttype,
                    description : description,
                    storeid : storeid,
                    unitprice : unitPrice,
                    tax : tax,
                    deposit : deposit,
                    sid : sid
                }
                if(cartOnly){
                    $data["cartOnly"] = 1;
                }
                $.ajax({
                    url: '/ajax/addtocart/',
                    dataType: 'JSON',
                    type: 'POST',
                    data: $data,
                    success: function(data) {
                        updateCartNumbers();
                        $this.removeClass('hide');
                        $this.siblings(".fa").addClass('hide');
                        isUpdating = updateCartProductsList();
                    }
                });
            }
        });
     });


    jQuery('.js-cart').on('change','input[name="cart_temp[]"]',function(){
         var data = {cart_temp:[]}
        //if($('input[name="cart_temp[]"]:checked').length)
            $('input[name="cart_temp[]"]:checked').each(function(){
                data.cart_temp.push($(this).val());
            });
        /*else
            $('input[name="cart_temp[]"]').each(function(){
                data.cart_temp.push($(this).val());
            });*/
        $.ajax({
            url: '/ajax/setcartwhitwish/', 
            dataType: 'JSON',
            type: 'POST',
            data: data,
            success: function() {
                isUpdating =updateCartProductsList();
            }
        });
    });
    jQuery('.js-btn-checkout').on('click', function() {
        jQuery('.js-btn-checkout').css('pointer-events','none');
        jQuery('.js-btn-checkout').css('background-color','#878787');
    })
    jQuery('.js-cart').on('click', '.js-delete-group-items', function(){
        $(this).closest(".js-item-category").find(".js-delete-item").trigger("click");
    })
    jQuery('.js-cart').on('click', '.js-delete-item', function(){
        var url = jQuery('.js-cart .js-delete-item-url').val();
        var item = jQuery(this).closest('.js-item');
        var category = item.closest('.js-item-category');
        var total = jQuery('.js-cart').find('.js-grand-total');
        var buttons = jQuery('.js-cart').find('.js-action-buttons');
        var no_item = jQuery('.js-no-items');
        var loadingIcon = item.find('.js-loading-icon');
        var id = item.attr('data-id');

        item.find(".js--fake_input_number").addClass('hide');
        loadingIcon.removeClass('hide'); 
        jQuery(this).addClass('hide');
                
        $.ajax({
            url:url,
            dataType: 'JSON',
            type: 'POST',
            data: {
                id : id
            },
            success: function(data){
                if (data.error) {
                    loadingIcon.addClass('hide');
                } else {
                    $.when(item.remove()).then(updateCartNumbers());
                    
                    if (category.find('.js-item').length == 0) {
                        category.remove();
                    }
                    if (jQuery('.js-cart').find('.js-item').length == 0) {
                        buttons.remove();
                        jQuery('.js-container-partial--step-breadcrumb .js-menu-cart-count').empty(); 
                        var toolBarCount = jQuery('.js-cart .js-menu-cart-count');
                         toolBarCount.html(0);
                        total.remove();
                    }
                    if (jQuery('.cart-listing').find('.js-item').length == 0) {
                        no_item.removeClass('hide');
                        jQuery(".container-checkout").addClass('hide');
                    }

                    if(parseInt(data.count) > 9){
                        jQuery('.js-menu-cart-count').html("9+");
                      }
                      else{
                        jQuery('.js-menu-cart-count').html(data.count);
                      }
                    
                }
                isUpdating = updateCartProductsList();
            }
        });
    });

     jQuery('.js-cart').on('click', '.js-btn-empty-cart', function(){
        if (!jQuery('.js-btn-empty-cart').hasClass('disabled') ) {
            var category = jQuery('.js-item-category');
            var total = jQuery('.js-cart').find('.js-grand-total');
            var no_item = jQuery('.js-no-items');
            $.ajax({
                url:'/ajax/cartPurge', 
              
                success: function(data){
                   category.find('.js-item').remove();
                   category.remove();
                   total.remove();
                   no_item.removeClass('hide');
                   jQuery(".container-checkout").addClass('hide');
                    //jQuery('.js-menu-cart-count, .js-breadcrumb-cart-count').html('');
                  jQuery('.js-btn-empty-cart, .js-btn-export').addClass('disabled'); 
                  jQuery('.cart-listing .js-action-buttons').addClass('hide'); 
                  jQuery('.js-menu-cart-count').empty(); 
                  var toolBarCount = jQuery('.js-cart .js-menu-cart-count');
                  toolBarCount.html(0);
                  footerPosition();    
                }
            });
        }
     });
     jQuery('.js-delivery-method').on('change', function(e) {
		var val = jQuery(e.target).val()
        if(val !='ship'){
			$('.js-shipping-address').hide()
		}
		else{
			$('.js-shipping-address').show()
		}

        if(val != 'pickup')
            $('.js-pick-up').show();
        else
            $('.js-pick-up').hide();

    });

    var delay = null;
    jQuery('.js-cart-checkout-shipping-address').on('change', function(){
        clearTimeout(delay);
        delay = setTimeout(updateCartTotals,500);
        //updateCartTotals();
    });

    jQuery('.js-cart-checkout-contry').on('change',function(){
        jQuery(this).closest('.form-group').parent().find('.form-group.js-select-tvp').hide()
        switch(jQuery(this).val()){
            case 'CA':
                jQuery(this).closest('.form-group').parent().find('.form-group.js-tvp-ca').show(); 
                break;
            case 'US':
                jQuery(this).closest('.form-group').parent().find('.form-group.js-tvp-usa').show(); 
                break;
        }
    })
});
/**
 * Updates the numbers in the page related to the cart items
 */
 function updateCartNumbers() {
    var count = 0;
    $.ajax({
        url:'/ajax/getCartItemCount',
        dataType: 'JSON',
        type: 'GET',
        success: function(data){
            count = data;
            //Total count in the menu
            var menuCount = jQuery('.js-menu-cart-count');
            if (count > 0){
                if(menuCount.hasClass('hide')) {
                    menuCount.removeClass('hide')
                }
                if(count > 9){
                    menuCount.html("9+");
                }
                else{
                    menuCount.html(count);
                }
            } else {
                menuCount.html('');
                menuCount.addClass('hide');
            }
        }
    });
}

function updateCartTotals()
{
    jQuery('.js-loader').removeClass('hide');
    $.ajax({
        url:'/ajax/getCartPricing',
        dataType: 'JSON',
        type: 'POST',
        data: $('form[name="cart_checkout"]').serialize(),
        success: function(data){
            jQuery('.js-loader').addClass('hide');
            $('.js-shipping-final-price').html(data.shippingPrice.toFixed(2));
            $('.js-subtotal-amount').html((data.subtotal).toFixed(2));
            $('.js-total').html((data.total).toFixed(2) );
            //$('.js-subtotal-amount').html(data.subtotal.toFixed(2));
            $('.js-tps').html(data.tps.toFixed(2));
            $('.js-tvp').html(data.tvp.toFixed(2));
            var rebuild = '';
            for(vslug in data.groupParts){
                var v = data.groupParts[vslug];
                var subtotal = 0;
                if(v.Year) rebuild +='<div class="text-left"><div class="item-brand text-bold color--primary">' +v.Year+ ' ' +v.Make+' '+ v.Model + '</div></div>';
                else rebuild +='<div class="text-left"><div class="item-brand text-bold color--primary">Miscellaneous</div></div>';

                rebuild += '<table class="c--table-price"><tbody>';
                for(iItem in v.Items){
                    var item = v.Items[iItem];
                    rebuild += '<tr><td class="qty">'+item.quantityID+'x </td><td>'+item.productCode+'</td><td>Unitrans </td><td>'+item.description+'</td><td>$<span class="js-item-subtotal">'+item.totalPrice.toFixed(2)+'</span></td></tr>';
                    if(item.core)
                        rebuild += '<tr><td colspan="4"><span class="fa fa-recycle"></span>Tax Evn.</td><td><span class="pull-right">$<span class="">'+item.core+'</span></td></tr>';
                    subtotal += item.totalPrice;
                    subtotal + item.core * item.quantityID
                }

                rebuild += '</tbody><tfoot><tr><th colspan="4">Total :</th><th>$ '+ subtotal.toFixed(2)+'</th></tr></tfoot></table>';
            }

            $('.js-product-list').empty().html(rebuild)
        }
    });
}

function updateCartProductsList(){
    updateCartTotals()
}