$( document ).ready(function(){
    $('[data-key-name-button]').click(function(){
        var keyName=$(this).attr('data-key-name-button');
        var htmlContent=$('[data-displayer-key-name="'+keyName+'"]').html();
        var sixId=$(this).attr('data-six-row-displayer');
        var threeId=$(this).attr('data-three-row-displayer');
        var twoId=$(this).attr('data-two-row-displayer');
        var tenId=$(this).attr('data-ten-row-displayer');    
        var isActive=$(this).hasClass('active');
    
        if(isActive){
            $('[data-key-name-button]').removeClass('active');
        }
        else{
            $('[data-key-name-button]').removeClass('active');
            $(this).addClass('active');
        }
    
        $("[data-ten-row-displayer-id='"+tenId+"']").html(htmlContent)
        $("[data-six-row-displayer-id='"+sixId+"']").html(htmlContent)
        $("[data-three-row-displayer-id='"+threeId+"']").html(htmlContent)
        $("[data-two-row-displayer-id='"+twoId+"']").html(htmlContent)
            
        if(isActive){
            closeSection('data-ten-row-displayer-id',tenId);
            closeSection('data-six-row-displayer-id',sixId);
            closeSection('data-three-row-displayer-id',threeId);
            closeSection('data-two-row-displayer-id',twoId);
        }
        else{
            expandSection('data-ten-row-displayer-id',tenId);
            expandSection('data-six-row-displayer-id',sixId);
            expandSection('data-three-row-displayer-id',threeId);
            expandSection('data-two-row-displayer-id',twoId);
        }
        
        initSubcatJS();
    
    });
});

function expandSection(sectionData,sectionId){

    $('['+sectionData+']').removeClass('active');
    $('['+sectionData+'="'+sectionId+'"]').addClass('active');

}
function closeSection(sectionData,sectionId){

    $('['+sectionData+'="'+sectionId+'"]').removeClass('active');

}


function initSubcatJS(){
    $('[data-sub-category-key-name]').click(function(e){
        var targetedElem = $(this).find('.displayer__content__sub-category__sub-container');
        var wasActive = targetedElem.hasClass("active");
        $('.displayer__content__sub-category__sub-container').removeClass("active");
        $('[data-sub-category-key-name]').removeClass("active");
        $('.displayer__content__sub-category__sub-container').css('height','0');
        
        if(!wasActive){
            $(this).addClass("active");
            targetedElem.addClass("active");
            targetedElem.css('height','auto');
            var heightCalc = targetedElem.height();

            targetedElem.css('height','0');
            setTimeout(function(){
                targetedElem.css('height', heightCalc + 'px');
            }
            ,1);
        }
        if($(e.target).closest('.displayer__content__sub-category__sub-container').length !== 0){
            targetedElem.addClass("active");
            targetedElem.css('height','auto');
            var heightCalc = targetedElem.height();
            targetedElem.css('height', heightCalc + 'px');
      }
    });
}