var grouptypeController = new function(){
    
    var _ = this;
    var groupetypID;
    var element;
    var grouptype_container_list = [];
    var grouptype_button_list = [];
    function construct(){
        element = $('.js-grouptype-controller');
        element.find('.js-btn-grouptype');
        element.find('.js-container-grouptype').each(function(){
            var container = new GrouptypeContainer(this, $("#year-holder").val(), $("#make-holder").val(), $("#model-holder").val());
            grouptype_container_list.push(container);
            container.Set
        });
        element.find('.grouptype-btn-container').each(function(){
            var button = new GrouptypeButton(this);
            grouptype_button_list.push(button);
            grouptype_container_list.forEach(function(container){
                if(container.GetID() == button.GetID()){
                    button.SetGrouptypeContainer(container);
                }
            });
        });
    }

    $(document).ready(construct);
}

