jQuery(document).ready(function() {


   jQuery('.js-button--share, .js-share-page .js-close').click(function(){
       jQuery('.js-mobile-overlay, .js-share-page').fadeToggle(); 

    });

    jQuery.validate({
     form : 'form#share-page',
      onError : function() {
        console.log('Validation of form failed!');
        return false; 
      },
      onSuccess : function($this) {
        $.post('/wp-content/themes/rsparts/partials/send-form-page.php', jQuery($this).serialize(), function( success ) {
            if(success == 'sent'){
                jQuery('.js-share-page .form-success').removeClass('hide');
            }
        });
        return false; 
      }
   });


});
