$( document ).ready(function(){
    /**
       * Modal search engine  
       */
     function bestsellerClickHandler (e) {
        var modal = jQuery('.js-container-search-tool--modal');
        var background = jQuery('.js-container-search-tool--overlay');

        background.fadeIn();
        modal.fadeIn();
        if (jQuery(this).data('url')) {
            var current_url = modal.find('.js-vehiculedefinition').parent().data('url');
            modal.find('.js-vehiculedefinition').parent().data('url', jQuery(this).data('url'));
            var resetUrl = function () {
                modal.find('.js-vehiculedefinition').parent().data('url', current_url);
                modal.find('.js-close-modal-best-seller').off('click', resetUrl);
            }
            modal.find('.js-close-modal-best-seller').on('click', resetUrl)
        }
        modal.find('#parttype-modal').val(jQuery(this).data('parttype'));
        modal.find('#category-modal').val(jQuery(this).data('category'));
        modal.find('#subcategory-modal').val(jQuery(this).data('subcategory'));
        modal.find('#manufacturer-modal').val(jQuery(this).data('manufacturer'));
    }
    $('.container-category').on('click','.js-bestseller',bestsellerClickHandler);
    $('.js-bestseller').on('click',bestsellerClickHandler);
    jQuery('.js-container-search-tool--modal').on('click', '.js-close-modal-best-seller', function () {
        jQuery('.js-container-search-tool--overlay').fadeOut();
        jQuery('.js-container-search-tool--modal').fadeOut();
    });
    jQuery('.header-logo img, .header-navigation--primary li:first-of-type').on('click', function () {
        var cookie = getCookie("webstore-segment"),
            substring = "tools";
        if (cookie.indexOf(substring) !== -1)
            Cookies.set('webstore-segment', 'light-vehicles', { expires: 365 });
        Cookies.set('webstore-persona', '', { expires: 365 });
    });



    jQuery('.js-step1-popup .js-vehiculedefinition').each(function () {

        var frontPage = jQuery(this).parents('.js-popup-step1');
        var $this = jQuery(this);
        jQuery(this).VehiculeDefinition({
            vehiculetype: jQuery(this).data('type'),
            year: {
                selector: '.js-year',
                type: jQuery(this).find('.js-make').data('url') ? 'ajax' : 'none',
                value: jQuery(this).find('.js-year').data('url')

            },
            make: {
                selector: '.js-make',
                type: !jQuery(this).find('.js-make').is(':disabled') ? 'ajax' : 'none',
                value: jQuery(this).find('.js-make').data('url')
            },
            model: {
                selector: '.js-model-search',
                type: 'ajax',
                value: jQuery(this).find('.js-model-search').data('url')
            },
            // :category' => '$4', ':subcategory' => '$5', ':partType' => '$6', ':manufacturer' => '$7'
            complete: function (data) {
                var modal = jQuery('.js-container-search-tool--modal');
                var manufacturerName = modal.find('#manufacturer-modal').val();
                if (manufacturerName == null){
                    manufacturerName = '';
                }
                var url = frontPage.data('url')
                    .replace('$1', encodeURI(data.year))
                    .replace('$2', encodeURI(data.make))
                    .replace('$3', encodeURI(data.model))
                    .replace('$4', encodeURI(modal.find('#category-modal').val()))
                    .replace('$5', encodeURI(modal.find('#subcategory-modal').val()))
                    .replace('$6', encodeURI(modal.find('#parttype-modal').val()))
                    .replace('$7', encodeURI(manufacturerName))

                if (data.motor != undefined && data.transmission != undefined) {
                    url += '?EngineConfigID=' + data.motor + "&transmissionID=" + data.transmission;
                }
                if (jQuery('#sid-modal').val())
                    url = url.replace('$4', jQuery('#sid-modal').val())

                jQuery('.js-loading-icon').removeClass('hide');
                window.location.href = url;
            }
        });
    });

})