jQuery(document).ready(function(){
    
     var $form = jQuery('#mc-embedded-subscribe-form');
  
        jQuery('form input[type="submit"]').on('click', function ( event ) {

           event.preventDefault();
            var email_address  = jQuery('#email_address').val();
            var honey_pot = jQuery('#mc-embedded-subscribe-form').find('#hp').val();
            if ( email_address != '' && honey_pot == undefined) { 
                register(email_address);  
            }
            return false;
        });
    


    function register(email) {
       //This action is an old fashion
        /*jQuery.ajax({
                url: '/wp-content/themes/kobrex/inc/store-address.php', // proper url to your "store-address.php" file
                type: 'POST', // <- IMPORTANT
                data: 'email_address=' + email + '&ajax=true',
                success: function(msg) {
                    console.log(msg);
                   var result = '';
                    if (msg == 200) {
                        jQuery('.js-msg-success').removeClass('hide'); 
                        jQuery('.js-msg-error').addClass('hide'); 
                    } else {
                        jQuery('.js-msg-error').removeClass('hide'); 
                        jQuery('.js-msg-success').addClass('hide'); 
                    }
                    
                }, error: function(msg){
                   jQuery('.js-msg-error').removeClass('hide'); 
                }

            });*/
    }
});